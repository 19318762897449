import { bool, func, object, string } from "prop-types";
import { Columns } from "project-structure";
import { capitalize } from "@material-ui/core";
import { ExportTotalsContainer } from "@dialogs/PdfExport/ExportView/ExportTotalsContainer";

export const ExportTotals = ({
  name,
  title,
  currentProjectStructure,
  translate,
  usesAnyValue,
  allowShowingPrices,
}) => {
  
  const {
    settings,
    visibility,
    displayTotalPrice,
    displayTotalPriceMax,
    displayTotalHours,
    displayTotalHoursMax,
    displayTotalTime,
    displayTotalTimeMax,
    showMaxTotalHours,
    showMaxTotalTime,
    showMaxTotalPrice,
  } = currentProjectStructure;
  
  const {
    currencyObj,
    timeModifier,
  } = settings;
  
  
  const {
    apply,
    hideTotalTime,
    hideTotalCost,
    hideTotalHours
  } = visibility;
  
  switch(name) {
    case Columns.COST:
      if((apply && hideTotalCost) || !allowShowingPrices) return <></>
      return (
        <ExportTotalsContainer name={name} title={title}>
          {
            usesAnyValue &&
            <>
              { currencyObj.symbolStart || "" }
              { displayTotalPrice }
              { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
            </>
          }
          { showMaxTotalPrice && "  -  "}
          { showMaxTotalPrice && (
            <>
              { currencyObj.symbolStart || "" }
              { displayTotalPriceMax }
              { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
            </>
          )}
        </ExportTotalsContainer>
      )
    case Columns.HOURS:
      if(apply && hideTotalHours) return <></>
      return (
        <ExportTotalsContainer name={name} title={title}>
            {
              usesAnyValue &&
              <>
                { displayTotalHours }
                {" "}
                {translate(`times.${timeModifier === 1 ? "h" : "day"}`)}
              </>
            }
            { showMaxTotalHours && "  -  " }
            { showMaxTotalHours && (
              <>
                { displayTotalHoursMax }
                {" "}
                {translate(`times.${timeModifier === 1 ? "h" : "day"}`)}
              </>
            )}
        </ExportTotalsContainer>
      )
    case Columns.TIME:
      if(apply && hideTotalTime) return <></>
      return (
        <ExportTotalsContainer name={name} title={title}>
            {
              usesAnyValue &&
              <>
                { displayTotalTime }
                { " " }
                { translate("times.month") }
              </>
            }
            { showMaxTotalTime &&  "  -  "}
            { showMaxTotalTime && (
              <>
                { displayTotalTimeMax }
                { " " }
                { translate("times.month") }
              </>
            )}
        </ExportTotalsContainer>
      )
    default:
      return <></>
  }
}

ExportTotals.propTypes = {
  translate: func,
  name: string,
  title: string,
  currentProjectStructure: object,
  usesAnyValue: bool,
  allowShowingPrices: bool,
}