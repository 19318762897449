import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { TotalContainer } from "../components";
import { useMemo } from "react";
import { getProfitColor } from "@utils";
import { useTheme } from "@material-ui/core/styles";

export const TotalProfit = observer(( {
  allowEdition
}) => {
  const theme = useTheme();
  const structure = useStructureStore();
  
  const {
    settings,
    usesTwoValues,
    displayTotalCompanyProfit,
    displayTotalCompanyProfitMax,
    totalCompanyProfitability,
  } = structure;
  const { currencyObj, useMinMax, roundHours } = settings;
  
  const showMaxValue = usesTwoValues && displayTotalCompanyProfit !== displayTotalCompanyProfitMax;
  
  const color = useMemo(() => (
    getProfitColor(totalCompanyProfitability, theme)
  ), [totalCompanyProfitability, theme]);
  
  const handleProfitChange = (profit, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    structure.setTotalCompanyProfit(profit, key);
  };
  
  return (
    <TotalContainer
      totalName="Profit"
      min={displayTotalCompanyProfit}
      max={displayTotalCompanyProfitMax}
      showMaxValue={showMaxValue}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      style={{color}}
      editable={allowEdition}
      onValueChange={handleProfitChange}
      maxValue={10000000}
      showDecimals={!roundHours}
      allowNegative
    />
  );
})

TotalProfit.propTypes = {
  allowEdition: bool,
}