import { APIMethod, fetchData } from "@client";

export async function createTemplateQuery(
  uuid,
  data, //{ name, description, category, copyComments }
) {
  await fetchData(
    "/template",
    APIMethod.POST,
    {
      removeEmptyKeys: true,
      data: {
        uuid,
        ...data,
        copyComments: data.copyComments ? 1 : 0,
      }
    }
  );
}
