import { unescape } from "lodash";
import { mergeDeep } from "@utils";

const ulIndent = (text, indent=0) => (
  unescape(`${"    ".repeat(Number(indent)+1)}• ${text}`)
);
const olIndent = (text, order, indent=0) => (
  `${"    ".repeat(Number(indent)+1)}${order}. ${text}`
);

/**@todo:
 * fix <ol> indented numeration
 * handle hyperlinks
 * handle codeblocks
 * */

const defaultTextBlock = (text) => ({ font: { size: 10, scheme: "minor" }, text });
const defaultEndLineBlock = () => ({text: "\n"});
const listLineBlock = { tag: /<\/?li( class="ql-indent-(\d+)")?>/, block: (text, order, bullet, indent) => ({
    text: bullet
      ? ulIndent(text, indent)
      : order > 0
        ? olIndent(text, order, indent)
        : `    ${text}`
  })};
const rdiMap = [
  { tag: /<\/?p>/, block: defaultTextBlock },
  { tag: /<\/?blockquote>/, block: (text) => ({ font: { size: 11, scheme: "minor" }, text: ` |  ${text}` }) },
  { tag: /<\/?pre>/, block: (text) => ({ font: { size: 10, scheme: "minor", color: { argb: "#FF999999" } }, text }) },
  { tag: /<\/?code>/, block: (text) => ({ font: { size: 10, scheme: "minor", color: { argb: "#FF999999" } }, text }) },
  { tag: /<\/?strong>/, block: (text) => ({ font: { bold: true }, text}) },
  { tag: /<\/?em>/, block: (text) => ({ font: { italic: true }, text}) },
  { tag: /<\/?s>/, block: (text) => ({ font: { strike: true }, text}) },
  { tag: /<\/?u>/, block: (text) => ({ font: { underline: "single"}, text}) },
  { tag: /<\/?ol>/, block: defaultEndLineBlock, initOrder: true},
  { tag: /<\/?ul>/, block: defaultEndLineBlock, useBullet: true },
  listLineBlock,
  { tag: /<\/?h1>/, block: (text) => ({ font: { size: 20, scheme: "minor" }, text }) },
  { tag: /<\/?h2>/, block: (text ) => ({ font: { size: 16, scheme: "minor" }, text }) },
  { tag: /<\/?h3>/, block: (text) => ({ font: { size: 12, scheme: "minor" }, text }) },
];

export const mapToXlsRichDescriptions = (htmlString) => {
  let richText = [];
  
  if(!htmlString.match(/<\/?\w+ ?.*?>/g))
    return htmlString;
  
  const text = htmlString.replace(/<\/p>/g, " ").replace(/<\/?\w+ ?.*?>/g, "");
  
  const parseLine = (innerHtmlString, parentBlock, order, useBullet, indent) => {
    let orderOrBulletApplied = false;
    
    if(innerHtmlString === "<br>") {
      richText.push(
        parentBlock.block("\n", order, useBullet, indent)
      );
      return;
    }
    
    if(!innerHtmlString.match(/<\/?\w+ ?.*?>/g)) {
      richText.push(
        parentBlock.block(innerHtmlString, order, useBullet, indent)
      );
      richText.push(defaultEndLineBlock());
      return;
    }
    
    let enteredBlock = [parentBlock];
    
    const checkAndApplyOrderOrBullet = (text) => {
      if(orderOrBulletApplied || !(order || useBullet))
        return false;
      
      orderOrBulletApplied = true;
      richText.push(
        listLineBlock.block(text, order, useBullet, indent)
      );
      // enteredBlock[enteredBlock.length-1] = { block: defaultTextBlock };
      enteredBlock = [];
      return true;
    }
    
    innerHtmlString
      .split(/(<\/?\w+>)/)
      .forEach(part => {
        if(part === "")
          return;
        if(part.match(/(<\/?\w+>)/)) { //is tag
          checkAndApplyOrderOrBullet("");
          const matchedBlock = rdiMap.find(el => part.match(el.tag));
          if(part.match(/^<\//))
            enteredBlock.pop(-1);
          else
            enteredBlock.push(matchedBlock);
          return;
        }
        
        const applied = checkAndApplyOrderOrBullet(part);
        if(!applied) {
          if(!enteredBlock.length)
            richText.push(defaultTextBlock(part));
          else {
            richText.push(
              mergeDeep(...enteredBlock.slice().reverse().map(el => el.block?.(part) || defaultTextBlock(part)))
            );
          }
        }
        
      });
    
    richText.push(defaultEndLineBlock());
  }
  
  htmlString
    .split(/<\/(?:p|h.|ol|ul|blockquote)>/)
    .slice(0,-1)
    .forEach((part) => {
      const m = part.match(/^(<\w+>)(.*)/);
      if(!m)
        return;
      
      const tag = m[1], innerHtml = m[2];
      const matchedBlock = rdiMap.find(el => tag.match(el.tag));
      
      if(!matchedBlock)
        return;
      
      if(matchedBlock.initOrder || matchedBlock.useBullet) {
        let order = 1;
        innerHtml
          .split(/<\/li>/)
          .slice(0,-1)
          .forEach(line => {
            const match = line.match(listLineBlock.tag);
            parseLine(
              match?.[0] ? line.replace(match[0], "") : line,
              listLineBlock,
              order++,
              matchedBlock.useBullet,
              match?.[2]
            );
          });
        return;
      }
      
      parseLine(innerHtml, matchedBlock);
    })
  
  if(JSON.stringify(richText[richText.length-1]) === JSON.stringify(defaultEndLineBlock()))
    richText.pop(-1);
  
  return { text, richText }
}

export const mapXlsRichDescriptionsToHtml = (richText) => {
  let htmlString = "";
  
  let openedLine = [];
  let justHadNewLine = false;
  
  const openBlock = (tag) => {
    if(openedLine[openedLine.length-1] === tag)
      return;
    openedLine.push(tag);
    htmlString += `<${tag}>`
  }
  
  const closeBlock = () => {
    if(openedLine.length)
      htmlString += `</${openedLine.pop(-1).replace(/ class="ql-indent-\d+"/, "")}>`
  }
  
  const handleListBlock = (text, tag) => {
    if(!openedLine.includes(tag))
      openBlock(tag);
    if(openedLine.find(tag => tag.match(/^li ?.*$/)))
      closeBlock();
    const match = text.match(/^( *)(\d+\.|-) /);
    const indent = Math.floor(match[1].length/4);
    openBlock(indent > 1 ? `li class="ql-indent-${indent-1}"` : "li");
    htmlString += text.replace(match[0], "");
  }
  
  richText
    .forEach((line) => {
      if(line.text === "\n") {
        closeBlock();
        if(justHadNewLine) {
          htmlString += "<p><br></p>";
        }
        justHadNewLine = true;
        return;
      }
      
      justHadNewLine = false;
      
      if(line.text.match(/^( {4})*\d+. /)) {
        handleListBlock(line.text, "ol");
        return;
      }
      if(line.text.match(/^( {4})*- /)) {
        handleListBlock(line.text, "ul");
        return;
      }
      
      const isH1 = line.font?.size === 20,
        isH2 = line.font?.size === 16,
        isH3 = line.font?.size === 12,
        isBlockquote = line.font?.size === 11 && line.text.match(/^ | {2}/);
      
      if(!openedLine.length) {
        if(isH1)
          openBlock("h1")
        else if(isH2)
          openBlock("h2")
        else if(isH3)
          openBlock("h3")
        else if(isBlockquote)
          openBlock("blockquote")
        else
          openBlock("p")
      }
      
      let text = line.text;
      
      if(line.font?.bold)
        text = `<strong>${text}</strong>`;
      if(line.font?.italic)
        text = `<em>${text}</em>`;
      if(line.font?.strike)
        text = `<s>${text}</s>`;
      if(line.font?.underline)
        text = `<u>${text}</u>`;
   
      htmlString += text;
    })
  
  while(openedLine.length)
    closeBlock();
  
  return htmlString
}