import { fetchData } from "@client";

export async function getTemplatePreviewQuery(id) {
  const res = await fetchData(`/template/${id}`);
  return {
    structure: res.results.structure,
    comments: res.results.comments,
    version: {
      key: res.results.key,
      name: res.results.name,
      order: res.results.order,
    }
  };
}
