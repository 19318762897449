import { makeStyles } from "@material-ui/core/styles";
import { MAX_PAGE_WIDTH, MAX_MEDIA_WIDTH, CONTENT_WIDTH, EDITOR_TABLE_PADDING } from "@styles/themes";

const useStyle = makeStyles((theme) => ({
  scrollableContainer: {
    width: "100%",
    padding: "0 24px 16px",
    "& .vCon:not(:hover) > :first-child .vBut": {
      opacity: 0,
    },
  },
  pageFormat: {
    margin: "0 auto 8px",
    padding: 0,
    borderRadius: 5,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15) !important",
    width: "calc(100% - 48px)",
    overflow: "hidden",
  },
  scalableContainer: {
    width: CONTENT_WIDTH,
    paddingLeft: 24,
    paddingRight: 24,
    position: "relative",
  },
  proposalBar: {
    marginLeft: -24,
    marginRight: -24,
    width: `calc(100% + ${2 * 24}px)`,
  },
  titleContainer: {
    width: "100%",
    padding: "24px 24px 0",
    margin: "0 -24px",
    backgroundColor: theme.props.backgroundSecondary,
    boxSizing: "content-box",
  },
  titleBarRoot: {
    "&:not(.pdf-mode):not(.preview-mode)": {
      maxWidth: "100%",
      padding: `0 ${EDITOR_TABLE_PADDING.LARGE}px`,
      [`@media (max-width: ${MAX_MEDIA_WIDTH}px)`]: {
        maxWidth: MAX_PAGE_WIDTH,
        padding: 0,
      },
    },
    "&.pdf-mode &.preview-mode": {
      maxWidth: MAX_PAGE_WIDTH,
      padding: 0,
    },
  },
  titleContainerLast: {
    paddingBottom: 24,
    borderBottom: "1px solid " + theme.props.border,
  },
}));

export default useStyle;
