import { makeStyles } from "@material-ui/core/styles";
import { MAX_PAGE_WIDTH, EDITOR_TABLE_PADDING, MAX_MEDIA_WIDTH } from "@styles/themes";

const useStyle = makeStyles((theme) => ({
  root: {
    "&:not(.pdf-mode):not(.preview-mode)": {
      maxWidth: "100vw",
      padding: `0 ${ EDITOR_TABLE_PADDING.LARGE }px`,
      [`@media (max-width: ${MAX_MEDIA_WIDTH}px)`]: {
        maxWidth: MAX_PAGE_WIDTH,
        padding: 0,
      },
    },
    "&.pdf-mode &.preview-mode": {
      maxWidth: MAX_PAGE_WIDTH,
      padding: 0,
    },
  },
  collapse: {
    width: "100%",
    "&:not(.pdf-mode):not(.preview-mode)": {
      maxWidth: "100%",
      [`@media (max-width: ${MAX_MEDIA_WIDTH}px)`]: {
        maxWidth: MAX_PAGE_WIDTH,
      },
    },
    "&.pdf-mode &.preview-mode": {
      maxWidth: MAX_PAGE_WIDTH,
    },
  },
  tableTitle: {
    margin: "16px 0",
    minHeight: 48,
    "&:not(.pdf-mode):not(.preview-mode)": {
      maxWidth: "100%",
      [`@media (max-width: ${MAX_MEDIA_WIDTH}px)`]: {
        maxWidth: MAX_PAGE_WIDTH,
      },
    },
    "&.pdf-mode &.preview-mode": {
      maxWidth: MAX_PAGE_WIDTH,
    },
  },
  tableTitleExpandButton: {
    width: 32,
    marginRight: 8,
  },
  tableContainerWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: 0,
    boxSizing: "border-box",
    flexDirection: "column",
    width: "100%",
  },
  tableContainer: {
    position: "relative",
    overflowX: "auto",
    overflowY: "hidden",
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    minWidth: "100%",
  },
  buttonSwitch: {
    marginLeft: 16,
  },
  overflows: {
    "& .display-overflow": {
      borderRightStyle: "solid !important",
      borderRightWidth: "2px !important",
      borderRightColor: `${ theme.props.border } !important`,
    }
  },
  whiteContainer: {
    width: "100%",
    padding: "24px 24px 0",
    margin: "0 -24px",
    backgroundColor: theme.props.backgroundSecondary,
    boxSizing: "content-box",
  },
  whiteContainerFirst: {
    paddingBottom: 24,
    borderTop: "1px solid " + theme.props.border,
  },
  whiteContainerLast: {
    paddingBottom: 24,
    borderBottom: "1px solid " + theme.props.border,
  },
}));

export default useStyle;
