import { useEffect, useMemo, useState } from "react";
import { bool, string, arrayOf, oneOfType, object, shape, number } from "prop-types";
import {
  EditorCommentsStore,
  EditorCommentsStoreProvider,
  ProjectEditorStore,
  ProjectEditorStoreProvider,
  StructureStoreProvider
} from "@stores";
import { StructureModel, structureParser, unzipStructure } from "project-structure";
import { PageCircularProgress, EditorContainer, TableBreakdown } from "@components";
import { PROJECT_TYPE } from "@utils";
import { Grid } from "@material-ui/core";

export const TemplatePreview = ({
  versionData,
  projectStructure,
  workTypes,
  commentList,
  useNewWorkTypes,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [structure, setStructure] = useState(null);
  
  const commentStore = useMemo(() => new EditorCommentsStore({
      isProposal: true,
      useComments: commentList?.length > 0,
      commentList,
    }), []);
  
  const store = useMemo(() => new ProjectEditorStore({
    projectType: PROJECT_TYPE.STANDARD,
    workTypes,
    defaultProjectVersionsForPreview: [versionData],
  }), []);

  useEffect(() => {
    (async () => {
      let structureData;
      if(typeof projectStructure === "string" && projectStructure[0] !== "{") {// may be blank
        try {
          structureData = JSON.parse(unzipStructure(projectStructure));
        } catch(e) {
          try {
            structureData = JSON.parse(unzipStructure(unzipStructure(projectStructure)));
          } catch(e) {
            console.log("TEMPLATE PARSE FAIL:", JSON.stringify(e));
          }
        }
      } else if(projectStructure)
        structureData = projectStructure;

      if(structureData) {
        const parsedStructure = await structureParser({
          structureData,
          workTypes,
          createNewTags: useNewWorkTypes
        });
        setStructure(StructureModel.create(parsedStructure));
        setLoading(false);
      }
    })();
  }, []);

  if (isLoading || !structure) return <PageCircularProgress />;

  return (
    <ProjectEditorStoreProvider value={store}>
      <EditorCommentsStoreProvider value={commentStore}>
        <StructureStoreProvider value={structure}>
          <EditorContainer>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
            >
              <TableBreakdown isProposal previewMode showChatThread />
            </Grid>
          </EditorContainer>
        </StructureStoreProvider>
      </EditorCommentsStoreProvider>
    </ProjectEditorStoreProvider>
  );
};

TemplatePreview.propTypes = {
  projectStructure: oneOfType([string, object]).isRequired,
  workTypes: arrayOf(object),
  useNewWorkTypes: bool,
  commentList: arrayOf(object),
  versionData: shape({
    key: number,
    order: number,
    name: string,
  }),
};
