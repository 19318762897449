import { useState } from "react";
import {
  useCheckProjectAccess,
  useProjectEditorStore,
  useStores,
  useStructureStore,
} from "@hooks";
import { useTranslation } from "react-i18next";
import ExcelJS from "exceljs";
import {
  dividerStyle,
  proposalHeaderStyle,
  sectionStyle,
  sectionTotalStyle,
  tableHeaderStyle,
  tableHeaderStyleCenter,
  taskFill,
  taskFont,
  totalCellStyle,
  turnedOffValueCellStyle,
  valueCellStyle,
  TITLE_ROW_HEIGHT,
} from "./xlsStyles";
import { isEmptyHtml, roundFloat } from "project-structure";
import { capitalize } from "@material-ui/core";
import { Columns, Tables, Totals } from "project-structure";
import { downloadFile, mapToXlsRichDescriptions } from "@utils";
import { getProposalExportFileName } from "@utils/getProposalExportFileName";
import { useParams } from "react-router-dom";
import { chunk } from "lodash";

export const useXlsExporter = () => {
  const { projectUuid } = useParams();
  const { userStore } = useStores();
  const { projectUsers } = useProjectEditorStore();
  const structure = useStructureStore();
  const { t } = useTranslation();
  
  const { hasSellerPrivileges } = useCheckProjectAccess(projectUsers);
  
  const [isLoading, setLoading] = useState(false);
  
  const {
    settings,
    workTypes,
    timelineWorkTypes,
    usesTwoValues,
    displayTotalPrice,
    displayTotalPriceMax,
    displayTotalHours,
    displayTotalHoursMax,
    displayTotalTime,
    displayTotalTimeMax,
    showMaxTotalPrice,
    showMaxTotalHours,
    showMaxTotalTime,
    sections,
    breakdownWorkTypes,
    hasFixedBreakdownPrice,
    displayFixedBreakdownPrice,
    displayFixedBreakdownPriceMax,
    showMaxFixedBreakdownPrice,
    totalDevelopmentTime,
    timelineBilling,
  } = structure;
  
  const exportProposal = async (unstyled=false) => {
    const {
      useMinMax,
      showPrices,
      currencyObj,
      timeModifier,
      viewLevel,
      valueLevel,
      sectionFormat,
      modifier,
      roundPrice,
      hasTeamMembersCol,
      hasTimeCol,
      usedTotals,
      visibleTables,
      visibleSummaryColumns,
      roundType,
      roundHours,
      roundLevel,
      usedRoundDecimals,
      showDecimals,
    } = settings;
    
    setLoading(true);
    
    const workbook = new ExcelJS.Workbook();
    
    workbook.creator = userStore.data.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    
    const worksheet = workbook.addWorksheet(t("views.editor.xls_worksheet"));
    
    const systemCols =
      (valueLevel >= 0 ? breakdownWorkTypes.length : 0) * (useMinMax ? 2 : 1) -
      1; // +5
    const breakdownDescriptionCol = 4
    const breakdownDescriptionColEnd = breakdownDescriptionCol + 4
    const breakdownTaskCols = breakdownDescriptionColEnd + 1;
    const taskCols = 4;
    const pricesCol =
      breakdownTaskCols +
      systemCols +
      (valueLevel >= 0 && showPrices && hasSellerPrivileges ? 1 : 0);
    const breakdownSystemCols = breakdownWorkTypes.map(
      (s, i) => breakdownTaskCols + (useMinMax ? 2 * i : i)
    );
    // const descriptionCol =
    //   pricesCol + (showPrices && hasSellerPrivileges && useMinMax ? 1 : 0) + 1;
    // const descriptionColEnd = descriptionCol + 6;
    // const costIndex = visibleSummaryColumns.findIndex(
    //   (col) => col.name === Columns.COST
    // );
    const totalSummaryCells = visibleSummaryColumns.reduce(
      (t, col) =>
        col.visible
          ? t + (useMinMax && ![Columns.RATE, Columns.TEAM].includes(col.name) ? 2 : 1)
          : t,
      0
    );
    
    // =========== COLUMN SETTINGS ===========
    worksheet.getColumn(1).width = 5; // work type + tasks
    worksheet.getColumn(2).width = 5; // work type + tasks
    worksheet.getColumn(3).width = 50; // work type + tasks
    for (let i = 4; i < pricesCol + 1 + (useMinMax ? 1 : 0); i++)
      worksheet.getColumn(i).width = 12; // tasks
    
    let currentCol = 1;
    let currentRow = 1;
    let cell;
    
    const buildTableSummary = () => {
      currentCol = taskCols;
      
      const lastSummaryCell = Math.max(
        taskCols - 1 + totalSummaryCells,
        taskCols
      );
      
      // =========== ROW SETTINGS ===========
      worksheet.getRow(currentRow).height = TITLE_ROW_HEIGHT; // summary row
      worksheet.getRow(currentRow + 1).height = useMinMax
        ? TITLE_ROW_HEIGHT
        : 36; // summary table header row
      
      // =========== SUMMARY HEADERS ===========
      worksheet.mergeCells(
        `A${currentRow}:${cti(lastSummaryCell, currentRow)}`
      );
      worksheet.getCell(`A${currentRow}`).value = t(
        "proposal_parts.editor_sections.summary"
      );
      if(!unstyled)
        worksheet.getCell(`A${currentRow}`).style = proposalHeaderStyle;
      currentRow++;
      
      // --------- work type ---------
      worksheet.mergeCells(`A${currentRow}:C${currentRow}`);
      worksheet.getCell(`A${currentRow}`).value = t(
        "common.work_type"
      );
      if(!unstyled)
        worksheet.getCell(`A${currentRow}`).style = tableHeaderStyle;
      
      visibleSummaryColumns.forEach(({ name }) => {
        switch (name) {
          case Columns.COST:
            if (hasSellerPrivileges) {
              cell = worksheet.getCell(cti(currentCol, currentRow));
              cell.value = t("views.editor.summary_table.cost_currency", {currency: currencyObj.symbolStart} );
              if(!unstyled)
                cell.style = tableHeaderStyleCenter;
              if (useMinMax) {
                worksheet.mergeCells(
                  `${cell.address}:${cti(currentCol + 1, currentRow)}`
                );
                currentCol++;
              }
            }
            break;
          case Columns.HOURS:
            cell = worksheet.getCell(cti(currentCol, currentRow));
            cell.value = t(`times.${timeModifier === 1 ? "hour" : "day"}`);
            if(!unstyled)
              cell.style = tableHeaderStyleCenter;
            if (useMinMax) {
              worksheet.mergeCells(
                `${cell.address}:${cti(currentCol + 1, currentRow)}`
              );
              currentCol++;
            }
            break;
          case Columns.RATE:
            if (hasSellerPrivileges) {
              cell = worksheet.getCell(cti(currentCol, currentRow));
              cell.value = `${t(
                `views.editor.summary_table.rate_${
                  timeModifier === 1 ? "hours" : "days"
                }`
              )} (${currencyObj.code})`;
              if(!unstyled)
                cell.style = tableHeaderStyleCenter;
            }
            break;
          case Columns.TEAM:
            cell = worksheet.getCell(cti(currentCol, currentRow));
            cell.value = t("views.editor.summary_table.team_members");
            if(!unstyled)
              cell.style = tableHeaderStyleCenter;
            break;
          case Columns.TIME:
            cell = worksheet.getCell(cti(currentCol, currentRow));
            cell.value = t("views.editor.summary_table.time");
            if(!unstyled)
              cell.style = tableHeaderStyleCenter;
            if (useMinMax) {
              worksheet.mergeCells(
                `${cell.address}:${cti(currentCol + 1, currentRow)}`
              );
              currentCol++;
            }
        }
        currentCol++;
      });
      
      currentRow++;
      
      // =========== SUMMARY ROWS ===========
      const setSummaryValues = (
        element,
        showValue,
        showPrice,
        currentCol
      ) => {
        const {
          displayHours,
          displayHoursMax,
          displayPrice,
          displayPriceMax,
          displayTime,
          displayTimeMax,
          displayRate,
          showMaxPrice,
          showMaxHours,
          showMaxTime,
          teamMembers,
          totalTeamMembers,
          turnOff,
        } = element;
        
        let cell;
        
        const usedRounding = roundType && showDecimals ? 3 : roundHours;
        
        visibleSummaryColumns.forEach(({ name }) => {
          switch (name) {
            case Columns.COST:
              if (hasSellerPrivileges) {
                cell = worksheet.getCell(cti(currentCol, currentRow));
                if(!unstyled)
                  cell.style = valueCellStyle(false, undefined, turnOff);
                if (showPrice)
                  cell.value = displayPrice;
                
                if (usesTwoValues) {
                  currentCol++;
                  const cellMax = worksheet.getCell(
                    cti(currentCol, currentRow)
                  );
                  if (showMaxPrice) {
                    if (showPrice)
                      cellMax.value = displayPriceMax;
                    if(!unstyled) {
                      cell.style = valueCellStyle(false, "left", turnOff);
                      cellMax.style = valueCellStyle(false, "right", turnOff);
                    }
                  } else
                    worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
                }
              }
              break;
            case Columns.HOURS:
              cell = worksheet.getCell(cti(currentCol, currentRow));
              if(!unstyled)
                cell.style = valueCellStyle(false, undefined, turnOff);
              if (showValue)
                cell.value = roundFloat(displayHours, usedRounding, showDecimals, usedRoundDecimals);
              
              if (usesTwoValues) {
                currentCol++;
                const cellMax = worksheet.getCell(cti(currentCol, currentRow));
                if (showMaxHours) {
                  if(!unstyled) {
                    cell.style = valueCellStyle(false, "left", turnOff);
                    cellMax.style = valueCellStyle(false, "right", turnOff);
                  }
                  if (showValue) cellMax.value = roundFloat(displayHoursMax, usedRounding, showDecimals, usedRoundDecimals);
                } else
                  worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
              }
              break;
            case Columns.RATE:
              if (hasSellerPrivileges) {
                cell = worksheet.getCell(cti(currentCol, currentRow));
                if(!unstyled)
                  cell.style = valueCellStyle(false, undefined, turnOff);
                if (showValue) cell.value = displayRate;
              }
              break;
            case Columns.TEAM:
              cell = worksheet.getCell(cti(currentCol, currentRow));
              if(!unstyled)
                cell.style = valueCellStyle(false, undefined, turnOff);
              if (showValue)
                cell.value = totalTeamMembers || teamMembers || 1;
              break;
            case Columns.TIME:
              cell = worksheet.getCell(cti(currentCol, currentRow));
              if(!unstyled)
                cell.style = valueCellStyle(false, undefined, turnOff);
              if (showValue)
                cell.value = displayTime;
              
              if (usesTwoValues) {
                currentCol++;
                const cellMax = worksheet.getCell(cti(currentCol, currentRow));
                if(showMaxTime) {
                  if(!unstyled)
                    cellMax.style = valueCellStyle(false, undefined, turnOff);
                  if (showValue)
                    cellMax.value = displayTimeMax;
                } else
                  worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
              }
          }
          currentCol++;
        });
        
        currentRow++;
      };
      
      workTypes.forEach((workType) => {
        const {
          name,
          inBreakdown,
          hasOnlyCost,
          isOpened,
          resources,
          hasResources,
          turnOff,
        } = workType;
        
        worksheet.mergeCells(`A${currentRow}:C${currentRow}`);
        const cell = worksheet.getCell(`A${currentRow}`);
        cell.value = name;
        cell.font = taskFont(0, turnOff);
        
        let currentCol = taskCols;
        const showPrice = !hasResources || !isOpened;
        const showValue = showPrice && (!hasOnlyCost || inBreakdown);
        
        setSummaryValues(workType, showValue, showPrice, currentCol);
        
        if (isOpened)
          resources?.forEach((resource) => {
            worksheet.mergeCells(`B${currentRow}:C${currentRow}`);
            worksheet.getCell(`B${currentRow}`).value = resource.name;
            
            setSummaryValues(resource, true, true, currentCol);
          });
      });
      
      if (hasFixedBreakdownPrice) {
        let currentCol =
          4 +
          (hasTeamMembersCol ? 1 : 0) +
          (hasTimeCol ? (useMinMax ? 2 : 1) : 0) +
          (hasSellerPrivileges ? 1 : 0) +
          (useMinMax ? 1 : 0); // 3 + hours + rate + team + time + hours-max
        
        worksheet.mergeCells(`A${currentRow}:${cti(currentCol, currentRow)}`);
        let cell = worksheet.getCell(`A${currentRow}`);
        cell.value = t("views.editor.summary_table.fixed_costs");
        cell.font = taskFont(0);
        currentCol++;
        
        if (hasSellerPrivileges) {
          cell = worksheet.getCell(cti(currentCol, currentRow));
          if(!unstyled)
            cell.style = valueCellStyle(false, undefined);
          cell.value = displayFixedBreakdownPrice;
          currentCol++;
          
          if (useMinMax) {
            const cellMax = worksheet.getCell(cti(currentCol, currentRow));
            if (useMinMax && showMaxFixedBreakdownPrice) {
              cellMax.value = displayFixedBreakdownPriceMax;
              if(!unstyled) {
                cell.style = valueCellStyle(false, "left");
                cellMax.style = valueCellStyle(false, "right");
              }
            } else worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
          }
        }
        
        currentRow++;
      }
      
      // =========== TOTALS ===========
      if (workTypes.length && hasSellerPrivileges) {
        let currentCol = taskCols-1;
        
        cell = worksheet.getCell(cti(1, currentRow));
        worksheet.mergeCells(`${cell.address}:${cti(currentCol, currentRow)}`);
        cell.value = t(`views.editor.summary_table.totals.title`);
        if(!unstyled)
          cell.style = totalCellStyle(true);
        currentCol++;
        
        visibleSummaryColumns.forEach(({ name }) => {
          cell = worksheet.getCell(cti(currentCol, currentRow));
          if(!unstyled)
            cell.style = totalCellStyle();
          
          const usesTotalCost = usedTotals?.find(({ name }) => name === Totals.COST)?.visible;
          const usesTotalHours = usedTotals?.find(({ name }) => name === Totals.HOURS)?.visible;
          const usesTotalTime = usedTotals?.find(({ name }) => name === Totals.TIME)?.visible;
          
          switch ( name ) {
            case Columns.COST:
              if ( hasSellerPrivileges && usesTotalCost )
                cell.value = displayTotalPrice;
              break;
            case Columns.HOURS:
              if ( usesTotalHours )
                cell.value = displayTotalHours;
              break;
            case Columns.TIME:
              if ( usesTotalTime )
                cell.value = displayTotalTime;
              break;
          }
          currentCol++;
          
          if(usesTwoValues && ![Columns.TEAM, Columns.RATE].includes(name)) {
            const cellMax = worksheet.getCell(cti(currentCol, currentRow));
            if(!unstyled)
              cellMax.style = totalCellStyle();
            switch ( name ) {
              case Columns.COST:
                if ( hasSellerPrivileges && usesTotalCost && showMaxTotalPrice )
                  cellMax.value = displayTotalPriceMax;
                else worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
                break;
              case Columns.HOURS:
                if ( usesTotalHours && showMaxTotalHours )
                  cellMax.value = displayTotalHoursMax;
                else worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
                break;
              case Columns.TIME:
                if ( usesTotalTime && showMaxTotalTime )
                  cellMax.value = displayTotalTimeMax;
                else worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
                break;
            }
            currentCol++;
          }
        });
        
        currentRow++;
      }
    };
    
    const buildTableTimeline = () => {
      // =========== TIMELINE HEADERS ===========
      
      worksheet.mergeCells(
        `A${currentRow}:${cti(3 + totalDevelopmentTime, currentRow)}`
      );
      worksheet.getCell(`A${currentRow}`).value = t(
        "proposal_parts.editor_sections.timeline"
      );
      if(!unstyled)
        worksheet.getCell(`A${currentRow}`).style = proposalHeaderStyle;
      worksheet.getRow(currentRow).height = TITLE_ROW_HEIGHT; // timeline row height
      currentRow++;
      
      worksheet.mergeCells(`A${currentRow}:C${currentRow}`);
      worksheet.getCell(`A${currentRow}`).value = t(
        "views.editor.timeline_table.role"
      );
      if(!unstyled)
        worksheet.getCell(`A${currentRow}`).style = tableHeaderStyle;
      
      currentCol = 4;
      [...new Array(totalDevelopmentTime).keys()].forEach((i) => {
        cell = worksheet.getCell(cti(currentCol, currentRow));
        if(!unstyled)
          cell.style = tableHeaderStyleCenter;
        cell.value = `${capitalize(t("time.month"))} ${i + 1}`;
        
        currentCol++;
      });
      
      currentRow++;
      
      // =========== TIMELINE ROWS ===========
      const setTimelineValues = (element, displayValue, currentCol) => {
        const chunkedTimeline = chunk(element.presentedTimeline?.weeks || [], 4);
        
        
        [...new Array(totalDevelopmentTime).keys()].map((i) => {
          cell = worksheet.getCell(cti(currentCol, currentRow));
          if(!unstyled)
            cell.style = valueCellStyle();
          const v = chunkedTimeline[i]?.reduce((t, w) => t + (w.hours || 0), 0);
          if (displayValue && v /*i >= element.timelineStartMonth && typeof v === "number"*/)
            cell.value = v
              ? roundFloat(
                v / modifier,
                showDecimals ? roundHours : 0,
                false, usedRoundDecimals
              )
              : 0;
          
          currentCol++;
        });
        
        currentRow++;
      };
      
      timelineWorkTypes.forEach((workType) => {
        const { name, isOpened, resources, hasResources } = workType;
        
        worksheet.mergeCells(`A${currentRow}:C${currentRow}`);
        worksheet.getCell(`A${currentRow}`).value = name;
        
        let currentCol = taskCols;
        const displayValue = !hasResources || !isOpened;
        
        setTimelineValues(workType, displayValue, currentCol);
        
        if (isOpened)
          resources?.forEach((resource) => {
            worksheet.mergeCells(`B${currentRow}:C${currentRow}`);
            worksheet.getCell(`B${currentRow}`).value = resource.name;
            
            setTimelineValues(resource, true, currentCol);
          });
      });
      
      // billing row
      if (hasSellerPrivileges) {
        worksheet.mergeCells(`A${currentRow}:C${currentRow}`);
        worksheet.getCell(`A${currentRow}`).value = t(
          "views.editor.timeline_table.billing"
        );
        if(!unstyled)
          worksheet.getCell(`A${currentRow}`).style = totalCellStyle(false, true);
        currentCol = 4;
        
        const chunkedTimelineBilling = chunk(timelineBilling || [], 4)
          .map(weeks => ({
            month: weeks.reduce((t, w) => t + w, 0),
            weeks,
          }));
        
        chunkedTimelineBilling.map(( { month }) => {
          cell = worksheet.getCell(cti(currentCol, currentRow));
          if(!unstyled)
            cell.style = totalCellStyle();
          if (month)
            cell.value = roundFloat(
              month,
              roundPrice ? 3 : 0, !roundPrice
            );
          currentCol++;
        });
        currentRow++;
      }
    };
    
    const buildTableBreakdown = () => {
      // =========== BREAKDOWN HEADERS ===========
      worksheet.getRow(currentRow).height = TITLE_ROW_HEIGHT; // breakdown row
      worksheet.mergeCells(
        `A${currentRow}:${cti(pricesCol + (useMinMax ? 1 : 0), currentRow)}`
      );
      worksheet.getCell(`A${currentRow}`).value = t(
        "proposal_parts.editor_sections.breakdown"
      );
      if(!unstyled)
        worksheet.getCell(`A${currentRow}`).style = proposalHeaderStyle;
      currentRow++;
      
      // --------- section / task ---------
      worksheet.mergeCells(`A${currentRow}:C${currentRow + 1}`);
      worksheet.getCell(`A${currentRow}`).value = t(
        "proposal_parts.section_title." + viewLevel
      );
      if(!unstyled)
        worksheet.getCell(`A${currentRow}`).style = tableHeaderStyle;
      
      // --------- description ---------
      cell = worksheet.getCell(cti(breakdownDescriptionCol, currentRow));
      worksheet.mergeCells(
        `${cell.address}:${cti(breakdownDescriptionColEnd, currentRow + 1)}`
      );
      cell.value = t("common.desc");
      if(!unstyled)
        cell.style = tableHeaderStyle;
      
      // --------- workload ---------
      if (breakdownWorkTypes.length && valueLevel >= 0) {
        cell = worksheet.getCell(cti(breakdownTaskCols, currentRow));
        worksheet.mergeCells(
          `${cti(breakdownTaskCols, currentRow)}:${cti(breakdownTaskCols + systemCols, currentRow)}`
        );
        cell.value = t(
          "views.editor.summary_table.workload"
        );
        if(!unstyled)
          cell.style = tableHeaderStyleCenter;
      }
      
      // --------- prices ---------
      if (valueLevel >= 0 && showPrices && hasSellerPrivileges) {
        cell = worksheet.getCell(cti(pricesCol, currentRow));
        worksheet.mergeCells(
          `${cell.address}:${cti(
            pricesCol + (useMinMax ? 1 : 0),
            currentRow + 1
          )}`
        );
        cell.value = `${t("views.editor.price")} (${
          currencyObj.symbolStart || currencyObj.symbolEnd
        })`;
        if(!unstyled)
          cell.style = tableHeaderStyleCenter;
      }
      
      currentRow++;
      
      // --------- work types ---------
      if (valueLevel >= 0)
        breakdownWorkTypes.forEach((system, i) => {
          const cell = worksheet.getCell(
            cti(breakdownSystemCols[i], currentRow)
          );
          cell.value = system.name;
          if(!unstyled)
            cell.style = tableHeaderStyleCenter;
          if (useMinMax)
            worksheet.mergeCells(
              `${cell.address}:${cti(breakdownSystemCols[i] + 1, currentRow)}`
            );
        });
      
      currentRow++;
      
      // =========== BREAKDOWN TASKS ===========
      const fillElementColumns = (
        element,
        usedWorkTypes,
        level,
        forceValueRender = false,
        noDesc = false,
        noMerges = false
      ) => {
        const renderNoValue =
          !forceValueRender &&
          element.isOpened &&
          element.children?.length &&
          level + 1 <= valueLevel;
        
        // --------- description ---------
        cell = worksheet.getCell(cti(breakdownDescriptionCol, currentRow));
        worksheet.mergeCells(
          `${cell.address}:${cti(breakdownDescriptionColEnd, currentRow)}`
        );
        if (!noDesc && !isEmptyHtml(element.content)) {
          cell.value = element.content
            ? mapToXlsRichDescriptions(element.content)
            : "";
        }
        
        // --------- work types ---------
        
        const showTaskDecimals = showDecimals || (
          typeof roundLevel === "number"
          && typeof element.lvl === "number"
          && element.lvl > roundLevel
        );
        
        const usedRounding = roundType && showDecimals ? 3 : roundHours;
        
        if (valueLevel >= 0)
          breakdownWorkTypes.forEach((workType, i) => {
            cell = worksheet.getCell(cti(breakdownSystemCols[i], currentRow));
            const cellMax = worksheet.getCell(
              cti(breakdownSystemCols[i] + 1, currentRow)
            );
            
            const skipWorkType = !usedWorkTypes.includes(workType.id);
            
            const value = roundFloat(
              element.getDisplayHoursForWorkType(workType.id),
              usedRounding, showTaskDecimals, usedRoundDecimals
            );
            const valueMax = roundFloat(
              element.getDisplayHoursMaxForWorkType(workType.id),
              usedRounding, showTaskDecimals, usedRoundDecimals
            );
            const showValueMax = usesTwoValues && value !== valueMax;
            const workTypeIsOff = element.isWorkTypeOff(workType.id);
            
            const forceCenter = renderNoValue || skipWorkType || !showValueMax;
            
            if(!unstyled)
              cell.style = valueCellStyle(
                level === 0,
                useMinMax && !forceCenter ? "left" : "center",
                element.isTurnedOff || workTypeIsOff
              );
            if (useMinMax) {
              if (!forceCenter) {
                if(!unstyled)
                  cell.style = valueCellStyle(
                    level === 0,
                    useMinMax ? "right" : "center",
                    element.isTurnedOff || workTypeIsOff
                  );
              } else if (!noMerges)
                worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
            }
            
            if (skipWorkType) return;
            
            if (!renderNoValue) {
              cell.value = value;
              if (useMinMax && !forceCenter) cellMax.value = valueMax;
            }
          });
        
        // --------- prices ---------
        if (valueLevel >= 0 && showPrices && hasSellerPrivileges) {
          cell = worksheet.getCell(cti(pricesCol, currentRow));
          if(!unstyled)
            cell.style = valueCellStyle(
              level === 0,
              useMinMax && element.showMaxPrice ? "left" : "center",
              element.isTurnedOff
            );

          if (!renderNoValue) cell.value = roundFloat(element.displayPrice, roundPrice ? 3 : 0, !roundPrice);
          
          if (useMinMax) {
            const cellMax = worksheet.getCell(cti(pricesCol + 1, currentRow));
            if (element.showMaxPrice) {
              if(!unstyled)
                cellMax.style = valueCellStyle(
                  level === 0,
                  useMinMax ? "right" : "center",
                  element.isTurnedOff
                );
              if (!renderNoValue) cellMax.value = roundFloat(element.displayPriceMax, roundPrice ? 3 : 0, !roundPrice);
            } else if (!noMerges) {
              worksheet.mergeCells(`${cell.address}:${cellMax.address}`);
            }
          }
        }
      };
      
      const renderModule = (task, usedWorkTypes, level = 1) => {
        let cell = worksheet.getCell(cti(breakdownDescriptionCol - 1, currentRow));
        worksheet.mergeCells(`${cti(level, currentRow)}:${cell.address}`);
        cell.value = task.name;
        if(!unstyled) {
          cell.fill = taskFill(level);
          cell.font = taskFont(level, task.isTurnedOff);
        }
        
        fillElementColumns(task, usedWorkTypes, level);
        
        if (task.isTurnedOff && !unstyled)
          worksheet.getCell(cti(breakdownDescriptionCol, currentRow)).style =
            turnedOffValueCellStyle;
        
        currentRow++;
        
        // --------- submodules ---------
        if (task.isOpened && task.children?.length) {
          task.children?.forEach((child) =>
            renderModule(child, usedWorkTypes, level + 1)
          );
        }
      };
      
      // =========== BREAKDOWN SECTIONS ===========
      sections.forEach((section, i) => {
        worksheet.getCell(`A${currentRow}`).value = section.name;
        if(!unstyled)
          worksheet.getCell(`A${currentRow}`).style = sectionStyle;
        
        fillElementColumns(
          section,
          section.usedWorkTypes,
          0,
          sectionFormat === 1,
          false,
          sectionFormat > 1
        );
        worksheet.mergeCells(
          `A${currentRow}:${cti(
            breakdownDescriptionCol - 1,
            currentRow
          )}`
        );
        
        currentRow++;
        
        // --------- tasks ---------
        
        section.children?.forEach((task) =>
          renderModule(task, section.usedWorkTypes)
        );
        
        if (sectionFormat === 2) {
          worksheet.mergeCells(
            `A${currentRow}:${cti(breakdownDescriptionCol - 1, currentRow)}`
          );
          worksheet.getCell(`A${currentRow}`).value = t("common.subtotal");
          if(!unstyled)
            worksheet.getCell(`A${currentRow}`).style = sectionTotalStyle;
          
          fillElementColumns(section, section.usedWorkTypes, 0, true, true);
          currentRow++;
        }
        
        // --------- spacing row ---------
        if (i + 1 < sections.length) {
          cell = `A${currentRow}:${cti(pricesCol + (useMinMax ? 1 : 0), currentRow)}`;
          worksheet.mergeCells(cell);
          if(!unstyled)
            worksheet.getCell(cell).style = dividerStyle;
          currentRow++;
        }
      });
    };
    
    visibleTables.forEach(({ name }) => {
      switch (name) {
        case Tables.SUMMARY:
          buildTableSummary();
          break;
        case Tables.TIMELINE:
          buildTableTimeline();
          break;
        case Tables.BREAKDOWN:
          buildTableBreakdown();
      }
      currentRow += 2;
    });
    
    // =========== EXPORT & DOWNLOAD ===========
    workbook.xlsx
      .writeBuffer({
        base64: true,
      })
      .then(function (xls64) {
        const data = new window.Blob([xls64], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        
        downloadFile(
          getProposalExportFileName(projectUuid, "xlsx"),
          data
        );
      })
      .catch(function (error) {
        console.error(error.message);
      });
    
    setLoading(false);
  };
  
  return {
    isLoading,
    exportProposal,
  };
};

function cti(colIndex, rowIndex) {
  if (!colIndex && colIndex !== 0) return rowIndex || 0;
  colIndex = Math.floor(Math.abs(colIndex));
  let col = "",
    rest;
  while (colIndex > 0) {
    rest = (colIndex - 1) % 26;
    col = String.fromCharCode(65 + rest) + col;
    colIndex = parseInt((colIndex - rest) / 26);
  }
  return rowIndex || rowIndex === 0 ? col + rowIndex : col;
}