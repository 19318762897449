import { createContext, useEffect, useRef, useState } from "react";
import { func, node } from "prop-types";
import {
  useStores,
  useProjectEditorStore,
  useEditorCommentStore,
  useWorkspaceWebsocket,
  useCheckProjectAccess
} from "@hooks";
import { useSnackbar } from "notistack";
import { applyPatch } from "mobx-state-tree";
import { hashCode } from "project-structure";
import { EditorCommands } from "@client";
import {
  EDITOR_CONTAINER_ID,
  PROJECT_STATUS,
  PROPOSAL_STEPPER_ID,
  SNACKBAR_WEBSOCKET_RESTORED
} from "@utils";
import { useTranslation } from "react-i18next";
import {
  fetchProposalCommentsQuery,
  saveProjectCoverQuery
} from "@query";
import { MAX_PAGE_WIDTH } from "@styles/themes";

export const EditorSocketContext = createContext();

export const EditorSocketProvider = ({ children, reloadProjectData }) => {

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { userStore, stateStore } = useStores();
  const editorStore = useProjectEditorStore();
  const commentStore = useEditorCommentStore();
  const {
    init,
    socket,
    connectionOnline,
    sendAction,
    requestProjectRename,
  } = useWorkspaceWebsocket();
  
  const [isSetup, setSetupStatus] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasConnectionError, setConnectionError] = useState(false);
  const editionPrivileges = useRef({
    joinEdition: false,
    joinComments: false
  });

  const lastSentAction = useRef(null);
  const projectJoined = useRef(null);
  const connectionOnlineSnackbar = useRef(null);
  const connectionRestoredSnackbar = useRef(null);
  
  const { hasEditorPrivileges } = useCheckProjectAccess(editorStore.projectUsers);
  
  // --------- connections ---------
  
  const onProjectJoinError = ({ message }) => {
    console.error("ERROR WHILE JOINING PROJECT: ", message)
    stateStore.setProjectCreatorProgressError(true, message);
  }
  
  const onProjectCreatorProgress = ({ progress, stepName }) => {
    stateStore.setProjectCreatorProgress(progress, stepName);
  }
  
  const joinProposal = (projectHash) => {
    editionPrivileges.current = {
      joinEdition: false,
      joinComments: Boolean(projectHash)
    }
    
    setupProjectEvents(
      true,
      false,
      Boolean(projectHash),
    );
    
    socket.current.emit(EditorCommands.JOIN_PROPOSAL, {
      commentHash: projectHash,
      ...editionPrivileges.current,
    });
    projectJoined.current = { projectUuid: projectHash, projectHash };
  };
  
  const joinProject = (projectUuid, projectHash, isCreated, versionKey) => {
    editionPrivileges.current = {
      joinEdition: true,
      joinComments: hasEditorPrivileges && projectHash
    }
    
    console.log("JOINING PROJECT", projectUuid)
    
    setupProjectEvents(
      true,
      true,
      hasEditorPrivileges && projectHash,
    );
    
    socket.current.emit(EditorCommands.JOIN_PROJECT, {
      projectUuid,
      versionKey,
      newProject: !isCreated,
      commentHash: projectHash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
      avatar: userStore.data.avatar,
      ...editionPrivileges.current,
    });
    projectJoined.current = { projectUuid, projectHash };
  };
  
  const closeProject = () => {
    if(!projectJoined.current)
      return;
    console.log("CLOSING PROJECT");
    setupProjectEvents(
      false,
      editionPrivileges.current.openEditionChannel,
      editionPrivileges.current.openCommentsChannel
    );
    editionPrivileges.current = undefined;
    socket.current.emit(EditorCommands.CLOSE_PROJECT, {
      projectUuid: projectJoined.current.projectUuid,
      commentHash: projectJoined.current.projectHash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      ...editionPrivileges.current,
    });
    projectJoined.current = null;
    
    lastSentAction.current = "END";
    closeSnackbar(connectionOnlineSnackbar.current);
    closeSnackbar(connectionRestoredSnackbar.current);
    setUsers([]);
  };
  
  const setupProjectEvents = (on, openEditionChannel, openCommentsChannel) => {
    if(!on) {
      socket.current.removeAllListeners();
      return;
    }
    
    // socket.current.on(EditorCommands.CONNECT_ERROR, onDisconnect);
    // socket.current.on(EditorCommands.DISCONNECT, onDisconnect);
    socket.current.on(EditorCommands.USER_JOINED, onJoined);
    socket.current.on(EditorCommands.USER_LEFT, onLeft);
    // socket.current.on(EditorCommands.CONNECTION, onReconnect);
    // socket.current.on(EditorCommands.RECONNECTION, onReconnect);
    socket.current.on(EditorCommands.JOIN_PROJECT, onProjectJoinError); //only join errors will come on this channel
    socket.current.on(EditorCommands.PROJECT_PROGRESS, onProjectCreatorProgress);
    socket.current.on(EditorCommands.PROJECT_WAS_CREATED, onProjectCreationEnd);
    
    if(openEditionChannel || !on) {
      socket.current.on(EditorCommands.USER_MOVE, onMoving);
      socket.current.on(EditorCommands.PATCH, onStructurePatch);
      socket.current.on(EditorCommands.CHANGE_ERROR, onProjectChangeError);
      socket.current.on(EditorCommands.VERSION_LIST, onReceiveVersionList);
      socket.current.on(EditorCommands.VERSION_REQUEST, onReceiveVersion);
      socket.current.on(EditorCommands.VERSION_NEW, onCreateVersion);
      socket.current.on(EditorCommands.VERSION_REMOVE, onRemoveVersion);
      socket.current.on(EditorCommands.VERSION_RENAME, onRenameVersion);
      socket.current.on(EditorCommands.VERSION_ORDER, onReorderVersion);
      socket.current.on(EditorCommands.VERSION_LOCK, onVersionLock);
      socket.current.on(EditorCommands.VERSION_VISIBILITY, onVersionVisibility);
      socket.current.on(EditorCommands.WORKTYPE_ADD, onAddNewWorkType);
      socket.current.on(EditorCommands.PERMIT_ADD, onWorkTypeAssignment);
      socket.current.on(EditorCommands.PERMIT_REMOVE, onWorkTypeRevoke);
      socket.current.on(EditorCommands.PERMIT_STATUS, onWorkTypeStatus);
      socket.current.on(EditorCommands.PERMIT_USER_VISIBILITY, onUserWorkTypesStatus);
      socket.current.on(EditorCommands.COVER_ADD, onCoverAdd);
      socket.current.on(EditorCommands.COVER_REMOVE, onCoverRemove);
      socket.current.on(EditorCommands.COVER_PAGES, onCoverUsedPagesUpdate);
      socket.current.on(EditorCommands.COVER_SCALE, onCoverScaleUpdate);
      socket.current.on(EditorCommands.SHARE_SETTINGS, onShareSettingsUpdate);
      
      socket.current.on(EditorCommands.USER_ADD, onProjectUserAdd);
      socket.current.on(EditorCommands.USER_REMOVE, onProjectUserRemove);
      socket.current.on(EditorCommands.USER_PROMOTE, onProjectUserPromote);
      
      socket.current.on(EditorCommands.PROJECT_RENAME, onProjectRename);
      socket.current.on(EditorCommands.PROJECT_REMOVE, onProjectRemove);
      socket.current.on(EditorCommands.PROJECT_ARCHIVE, onProjectArchive);
      socket.current.on(EditorCommands.PROJECT_STATUS, onProjectStatusUpdate);
    }
    if(openCommentsChannel || !on) {
      socket.current.on(EditorCommands.COMMENT_ADD, onCommentAddOrEdit);
      socket.current.on(EditorCommands.COMMENT_EDIT, onCommentAddOrEdit);
      socket.current.on(EditorCommands.COMMENT_REMOVE, onCommentRemove);
      socket.current.on(EditorCommands.COMMENT_REMOVE_PATH, onCommentPathRemove);
      socket.current.on(EditorCommands.COMMENT_MOVE, onCommentMove);
    }
    
    setSetupStatus(true);
  }

  const resetProjectData = async () => {
    if(!projectJoined.current) return;
    await reloadProjectData();
    socket.current.emit(EditorCommands.JOIN_PROJECT, {
      projectUuid: projectJoined.current.projectUuid,
      versionKey: editorStore.currentVersionKey,
      commentHash: projectJoined.current.projectHash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
      avatar: userStore.data.avatar,
      ...editionPrivileges.current,
    });
  }
  
  const refetchProjectComments = async () => {
    if(!commentStore || !projectJoined.current) return;
    const c = await fetchProposalCommentsQuery(projectJoined.current.projectUuid, editorStore.currentVersionKey);
    commentStore.setComments(c, editorStore.currentVersionKey);
  }
  
  useEffect(() => {
    if(isSetup && connectionOnline) {
      onReconnect();
    }
    
    if(!connectionOnline) {
      onDisconnect();
    }
  }, [connectionOnline]);

  const onReconnect = async () => {
    console.log("EDITOR RECONNECTED");
   
    if(editionPrivileges.current.joinEdition && projectJoined.current) {
      await resetProjectData();
      closeSnackbar(connectionOnlineSnackbar.current);
      connectionRestoredSnackbar.current = enqueueSnackbar(
        t("alerts.editor.connection_restored"),
        {
          key: SNACKBAR_WEBSOCKET_RESTORED,
          variant: "success",
          autoHideDuration: 2000
        }
      );
    }
    if(editionPrivileges.current.joinComments)
      await refetchProjectComments();
  };

  const onDisconnect = () => {
    console.log("EDITOR DISCONNECTED");
    if(lastSentAction.current !== "END" && editionPrivileges.current.joinEdition) {
      closeSnackbar(connectionRestoredSnackbar.current);
      connectionOnlineSnackbar.current = enqueueSnackbar(
        t("alerts.editor.connection_offline"),
        {
          persist: true,
          variant: "warning"
        }
      );
    }
    setUsers([]);
  };

  const madeIdenticalAction = (command, payload, additionalChecks) => {
    if (!lastSentAction.current) return false;
    return (
      lastSentAction.current.command === command &&
      lastSentAction.current.timestamp > payload.timestamp &&
      (!additionalChecks || additionalChecks(lastSentAction.current.payload))
    );
  };

  const saveAndSendAction = (command, payload) => {
    if (!socket.current?.connected) return;
    const timestamp = new Date().getTime();
    payload.timestamp = timestamp;
    lastSentAction.current = { command, payload, timestamp };
    sendAction(command, payload);
  };

  // --------- remote actions ---------

  const onJoined = (userData) => {
    // console.log("USER JOINED", userData);
    setUsers([...users, ...userData])
    // users.push(...userData);
    // console.log("CURRENT USERS", users);
  };

  const onLeft = (userIds) => {
    // console.log("USER LEFT", userIds);
    setUsers(users.filter((u) => !userIds.includes(u.socketId)));
    // console.log("CURRENT USERS", users);
  };
  
  const onProjectCreationEnd = ({ status }) => {
    editorStore.setCreatedStatus(status);
  }

  const onMoving = (data) => {
    if(!data?.id)
      return;
    
    const cursor = document.getElementById(hashCode(data.id));

    if (cursor) {
      let target =
        data.elementId &&
        document.querySelector(
          `[data-id="${data.elementId}"][data-t="${data.elementType}"]`
        );
      let parent,
        i = 0;

      if (data.elementPath && !target) {
        const l = data.elementPath.split("/").reverse();
        do {
          parent = document.querySelector(`[data-id="${l[i]}"]`);
          i++;
        } while (!parent && i < l.length);
      }

      if (target || !parent) {
        // const innerHeight = document.getElementById(TABLE_CONTAINER_ID).clientHeight;

        const scaleX =
          Math.min(MAX_PAGE_WIDTH, window.innerWidth) / Math.min(MAX_PAGE_WIDTH, data.vW);
        // const scaleY = innerHeight / data.vH;
        const offsetLeft =
          window.innerWidth < MAX_PAGE_WIDTH ? 24 : (window.innerWidth - MAX_PAGE_WIDTH) / 2;
        const x = Math.round(data.x * scaleX) + offsetLeft;
        const y = Math.round(data.y);

        cursor.style.top = `${y}px`;
        cursor.style.left = `${x}px`;
      } else if (parent) {
        const con = document.getElementById(EDITOR_CONTAINER_ID);
        if(!con) return;
        const stepperHeight =
          document.getElementById(PROPOSAL_STEPPER_ID)?.getBoundingClientRect()
            .height || 0;
        const par = parent.getBoundingClientRect();
        const l = parent.querySelector(".name")?.getBoundingClientRect()?.left;
        cursor.style.top = `${
          par.top +
          par.height / 2 +
          con.scrollTop -
          stepperHeight -
          con.getBoundingClientRect()?.y
        }px`;
        cursor.style.left = `${(l || par.left) + 24}px`;
      }
    }
  };
  
  const onProjectChangeError = (data) => {
    console.error("ERROR => Project not found", data);
    setConnectionError(true);
  }
  
  const onStructurePatch = (data) => {
    
    const structure = editorStore.projectVersions.find(
      (v) => v.key === data.versionKey
    )?.structure;
    if (structure) try {
      structure.historyManager.withoutUndo(() => {
        data.patches.forEach(p => {
          if(p.removesElement && p.identifier)
          structure.historyManager.clearByTargetId(p.identifier)
        })
        applyPatch(structure, data.patches);
      })
    } catch(e) {
      console.log("Error: applyPatch", e);
    }
  };

  const onReceiveVersionList = ({ message, versions }) => {
    if(message)
      console.error(message);
    if(versions)
        editorStore.setVersions(versions);
  }
  
  const onReceiveVersion = ({ message, versionData }) => {
    if(message)
      console.error(message);
    if(versionData)
      editorStore.setVersionData(versionData);
    
    stateStore.resetProjectCreatorProgress();
  };

  const onCreateVersion = ({ versionData, userSocketId }) => {
    editorStore.addNewVersion(versionData, userSocketId === socket.current.id);
  };

  const onRemoveVersion = ({ versionKey, newVersionData, versionName, userSocketId }) => {
    if(newVersionData && editorStore.currentVersion === versionKey) {
      editorStore.setVersionData(newVersionData);
      if(socket.current.id !== userSocketId)
        enqueueSnackbar(
          <p>
            {t("common.version")}
            <b>{` ${versionName} `}</b>
            {t("alerts.editor.version_removed")}
          </p>,
          { variant: "warning", autoHideDuration: 2000 }
        );
    }
    editorStore.removeSelectedVersion(versionKey);
  };

  const onRenameVersion = (data) => {
    if (
      madeIdenticalAction(
        EditorCommands.VERSION_RENAME,
        data,
        (p) => p.versionKey === data.versionKey
      )
    )
      return;
    const { versionKey, newName } = data;
    editorStore.editVersionName(versionKey, newName);
  };

  const onReorderVersion = (data) => {
    if (madeIdenticalAction(EditorCommands.VERSION_ORDER, data))
      return;
    editorStore.updateVersionOrder(data.newOrder);
  };
  
  const onVersionLock = (data) => {
    editorStore.setEditionLock(data.versionKey, data.locked);
  }
  
  const onVersionVisibility = (data) => {
    editorStore.setVersionHidden(data.versionKey, data.visible);
  }

  const onProjectRename = (data) => {
    if (madeIdenticalAction(EditorCommands.PROJECT_RENAME, data)) return;
    editorStore.setProjectName(data.newName);
  };
  
  const onProjectRemove = ({ projectUuid }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    editorStore.setProjectStatus(PROJECT_STATUS.REMOVED);
  }
  
  const onProjectArchive = ({ projectUuid }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    editorStore.setProjectStatus(PROJECT_STATUS.ARCHIVE);
  }
  
  const onProjectStatusUpdate = ({ projectUuid, status }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    editorStore.setProjectStatus(status);
  }
  
  const onProjectUserAdd = ({ projectUuid, ...userData }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    
    const observer = [...editorStore.projectUsers.observer];
    observer.push(userData)
    editorStore.setProjectUsers({
      ...editorStore.projectUsers,
      observer
    })
  }
  
  const onProjectUserPromote = ({ projectUuid, userUuid }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    
    const newOwner = editorStore.projectUsers.observer.find((u) => u.uuid === userUuid);
    const observer = [...editorStore.projectUsers.observer].filter((u) => u.uuid !== userUuid);
    if (editorStore.projectUsers.owner && editorStore.projectUsers.owner?.uuid !== editorStore.projectUsers.author.uuid)
      observer.push({ ...editorStore.projectUsers.owner });
    
    editorStore.setProjectUsers({
      ...editorStore.projectUsers,
      owner: newOwner,
      observer
    });
  };
  
  const onProjectUserRemove = ({ projectUuid, userUuid }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    
    let newOwner = editorStore.projectUsers.owner;
    const observer = [...editorStore.projectUsers.observer].filter((u) => u.uuid !== userUuid);
    if (newOwner && userUuid === newOwner.uuid) {
      observer.push({ ...newOwner });
      newOwner = editorStore.projectUsers.author;
    }
    
    editorStore.setProjectUsers({
      ...editorStore.projectUsers,
      owner: newOwner,
      observer
    });
  };

  const onAddNewWorkType = ({ id, name, backgroundColor }) => {
    editorStore.addCustomWorkType(id, name, backgroundColor);
  };

  const onWorkTypeAssignment = ({ workTypeId, userUuid }) => {
    editorStore.addLocalPermit(workTypeId, userUuid);
  };

  const onWorkTypeRevoke = ({ workTypeId, userUuid }) => {
    editorStore.removeLocalPermit(workTypeId, userUuid);
  };

  const onWorkTypeStatus = ({ workTypeId, status }) => {
    editorStore.updateLocalPermit(workTypeId, status);
  };

  const onUserWorkTypesStatus = ({ userId, status }) => {
    editorStore.setProjectUserWorkTypeVisibility(userId, status);
  };

  const onCoverAdd = (cover) => {
    editorStore.setPdfDocument(cover);
  };

  const onCoverRemove = () => {
    editorStore.removeDocument();
  };

  const onCoverUsedPagesUpdate = ({ docId, usedPages, tablePos }) => {
    editorStore.setUsedPages(usedPages, docId);
    if(tablePos)
      editorStore.setTablePos(tablePos, docId);
  };

  const onCoverScaleUpdate = ({ scale }) => {
    editorStore.setScale(scale.scale, scale.scaleValue, false);
  };
  
  const onCommentAddOrEdit = (payload) => {
    commentStore.addOrUpdateComment(payload);
  };
  
  const onCommentRemove = ({ commentId, parent }) => {
    commentStore.removeComment(commentId, parent);
  };
  
  const onCommentPathRemove = ({ version, place }) => {
    commentStore.removeCommentByPath(version, place);
  };
  
  const onCommentMove = ({ version, place, oldPlace }) => {
    commentStore.updateCommentPath(version, oldPlace, place);
  };
  
  const onShareSettingsUpdate = ({ settings }) => {
    editorStore.setAllShareLinkSettings(JSON.parse(settings));
  };

  // --------- USER ACTIONS ---------

  const sendMoveData = (
    clientX,
    clientY,
    elementType,
    elementPath,
    elementId
  ) => {
    sendAction(EditorCommands.USER_MOVE, {
      id: userStore.data.id,
      vW: window.innerWidth,
      // vH: containerHeight,
      x: clientX,
      y: clientY,
      elementType,
      elementPath,
      elementId,
    });
  };
  
  const requestStructurePatch = (patches, metadata) => {
    saveAndSendAction(EditorCommands.PATCH, {
      versionKey: editorStore.currentVersionKey,
      timestamp: new Date().getTime(),
      patches,
      metadata,
    });
  };

  const requestExistingVersion = (versionKey) => {
    saveAndSendAction(EditorCommands.VERSION_REQUEST, {
      projectUuid: projectJoined.current.projectUuid,
      userUuid: userStore.userUuid,
      versionKey,
    });
  };
  
  const requestNewVersion = (copyComments) => {
    saveAndSendAction(EditorCommands.VERSION_NEW, {
      originVersionKey: editorStore.currentVersionKey,
      copyComments,
    });
  };

  const requestVersionRename = (versionKey, newName) => {
    saveAndSendAction(EditorCommands.VERSION_RENAME, { versionKey, newName });
  };

  const requestVersionRemoval = (versionKey) => {
    saveAndSendAction(EditorCommands.VERSION_REMOVE, { versionKey });
  };

  const requestVersionVisibility = (versionKey, visible) => {
    saveAndSendAction(EditorCommands.VERSION_VISIBILITY, { versionKey, visible });
  };

  const requestVersionLock = (versionKey, locked) => {
    saveAndSendAction(EditorCommands.VERSION_LOCK, { versionKey, locked });
  };

  const requestVersionReorder = (movedVersionKey, newOrder) => {
    saveAndSendAction(EditorCommands.VERSION_ORDER, {
      movedVersionKey,
      newOrder,
    });
  };

  const sendNewWorkType = ({ id, name, backgroundColor }) => {
    saveAndSendAction(EditorCommands.WORKTYPE_ADD, {
      id,
      name,
      backgroundColor,
    });
  };

  const addNewPermit = (workTypeId, userUuid) => {
    saveAndSendAction(EditorCommands.PERMIT_ADD, { workTypeId, userUuid });
  };

  const removePermit = (workTypeId, userUuid) => {
    saveAndSendAction(EditorCommands.PERMIT_REMOVE, { workTypeId, userUuid });
  };

  const setProjectUserWorkTypeVisibility = (userId, status) => {
    saveAndSendAction(EditorCommands.PERMIT_USER_VISIBILITY, { userId, status: status ? 1 : 0 });
  };

  const setPermitStatus = (workTypeId, status) => {
    saveAndSendAction(EditorCommands.PERMIT_STATUS, { workTypeId, status });
  };

  const addPdfCover = (cover) => {
    sendAction(EditorCommands.COVER_ADD, {
      projectUuid: projectJoined.current.projectUuid,
      cover,
    });
  };

  const removeCover = () => {
    sendAction(EditorCommands.COVER_REMOVE, {
      projectUuid: projectJoined.current.projectUuid,
    });
  };
  
  const saveCoverChanges = async ({ usedPages, tablePos, scale }) => await (
     saveProjectCoverQuery(
      projectJoined.current.projectUuid,
      userStore.userUuid,
      editorStore.pdfDocument.id,
      {
        ...editorStore.pdfDocument.file,
        usedPages: usedPages || editorStore.usedPdfPages,
        tablePos: tablePos || editorStore.proposalTablePosition,
        scale: scale || editorStore.scale,
      }
    )
  )

  const updateCoverPages = async (docId, usedPages, tablePos) => {
    await saveCoverChanges({ usedPages, tablePos });
    saveAndSendAction(EditorCommands.COVER_PAGES, {
      projectUuid: projectJoined.current.projectUuid,
      docId,
      usedPages,
      tablePos
    });
  };

  const updateCoverScale = async (docId, scaleObj) => {
    await saveCoverChanges({ scale: scaleObj.scale });
    saveAndSendAction(EditorCommands.COVER_SCALE, {
      projectUuid: projectJoined.current.projectUuid,
      docId,
      scaleObj
    });
  };
  
  const requestCommentAdd = (userId, place, body, mentions, internal, parentId) => {
    sendAction(EditorCommands.COMMENT_ADD, {
      userId,
      version: editorStore.currentVersionKey,
      place,
      body,
      mentions,
      internal,
      parentId,
    });
  };
  
  const requestCommentEdit = (id, userId, place, body, mentions, parentId) => {
    sendAction(EditorCommands.COMMENT_EDIT, {
      id,
      userId,
      version: editorStore.currentVersionKey,
      place,
      body,
      mentions,
      parentId,
    });
  };
  
  const requestCommentRemove = (commentId, userId, parent) => {
    sendAction(EditorCommands.COMMENT_REMOVE, {
      commentId,
      userId,
      version: editorStore.currentVersionKey,
      parent
    });
  };
  
  const requestCommentPathRemove = (place) => {
    sendAction(EditorCommands.COMMENT_REMOVE_PATH, {
      version: editorStore.currentVersionKey,
      place
    });
  };
  
  const requestCommentMove = (oldPlace, place) => {
    sendAction(EditorCommands.COMMENT_MOVE, {
      version: editorStore.currentVersionKey,
      place,
      oldPlace
    });
  };
  
  const requestShareSettingsUpdate = (settings) => {
    sendAction(EditorCommands.SHARE_SETTINGS, {
      settings: JSON.stringify(settings)
    });
  };

  const value = {
    users,
    hasConnectionError,
    initSocket: init,
    closeProject,
    joinProject,
    joinProposal,
    
    sendMoveData,
    // requestStructureChange,
    requestStructurePatch,
    // requestStructureVersionChange,
    requestExistingVersion,
    requestNewVersion,
    requestVersionRename,
    requestVersionRemoval,
    requestVersionReorder,
    requestVersionVisibility,
    requestVersionLock,
    requestProjectRename,
    sendNewWorkType,
    addNewPermit,
    removePermit,
    setProjectUserWorkTypeVisibility,
    setPermitStatus,
    addPdfCover,
    removeCover,
    updateCoverPages,
    updateCoverScale,
    // requestRemoteSave,
    requestCommentAdd,
    requestCommentEdit,
    requestCommentRemove,
    requestCommentPathRemove,
    requestCommentMove,
    requestShareSettingsUpdate,
  };

  return (
    <EditorSocketContext.Provider value={value}>
      {children}
    </EditorSocketContext.Provider>
  );
};

EditorSocketProvider.propTypes = {
  children: node.isRequired,
  reloadProjectData: func,
};
