import { node, string } from "prop-types";
import { capitalize } from "@material-ui/core";

export const ExportTotalsContainer = ({
  name,
  title,
  children,
}) => (
  <div className="total">
    <p className={ `total-${ capitalize(name) }-title` }>
      { title }
    </p>
    <strong className={`total-${name}`}>
      { children }
    </strong>
  </div>
)

ExportTotalsContainer.propTypes = {
  name: string,
  title: string,
  children: node,
}