import { APIMethod, fetchData } from "@client";

export async function duplicateProjectQuery(
  uuid,
  name,
  copyComments=false,
) {
  return await fetchData(
    `/v1/project/structure/copy/${uuid}`,
    APIMethod.POST,
    { data: { name, copyComments: copyComments ? 1 : 0 }}
  );
}