import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { useParams, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@components";
import { OrDivider } from "@views/Auth/components/OrDivider/OrDivider";
import { MicrosoftSSO } from "@views/Auth/components/MicrosoftSSO/MicrosoftSSO";
import { pushGTagEvent } from "@utils";
import { RegisterForm } from "@forms";
import { registerUserQuery } from "@query";

export const Register = () => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const { code } = useParams();
  const { search } = useLocation();

  const defaultEmail = useMemo(
    () => new URLSearchParams(search).get("email"),
    []
  );
  const landingEmail = useMemo(
    () => new URLSearchParams(search).get("landingemail"),
    []
  );

  const [alertText, setAlertText] = useState();

  const handleSignUp = async (data) => {
    try {
      const userData = await registerUserQuery(data, code);
      if(!code) {
        pushGTagEvent("new_registration");
        stateStore.setActivationEmail(userData.email);
        setAlertText("views.register.successful");
      }
      
      userStore.saveUserData(userData);
      userStore.checkWorkspacesAndRedirect();
      if(code)
        userStore.setLoginProcessStatus(true);
    } catch (e) {
      if (e.response?.data?.errors?.length) {
        const errors = e?.response?.data?.errors;
        if (errors) return { errors };
      } else if (e.response?.data?.error) setAlertText(e.response.data.error);
      else setAlertText("errors.network");
    }
  };
  
  const handleAlertClose = () => {
    setAlertText(undefined)
    if(userStore.data)
      userStore.setLoginProcessStatus(true);
  }

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className="h-full pt-3"
    >
      <RegisterForm
        onSubmit={handleSignUp}
        isAccountActivation={!!code}
        defaultEmail={defaultEmail || landingEmail || undefined}
      />

      <OrDivider />
      <MicrosoftSSO register />

      <Alert
        isOpen={Boolean(alertText)}
        title={t(alertText)}
        acceptText={t("common.close")}
        onAccept={handleAlertClose}
      />
    </Grid>
  );
};
