import { createContext, useEffect, useRef, useState } from "react";
import { node } from "prop-types";
import { useStores } from "@hooks";
import { EditorCommands } from "@client";
import { Manager } from "socket.io-client";
import socketParser from "socket.io-msgpack-parser";

export const WorkspaceSocketContext = createContext();

export const WorkspaceSocketProvider = ({ children }) => {
  
  const { userStore } = useStores();
  const { messageStore } = useStores();
  
  const [connectionOnline, setConnectionOnline] = useState(false);
  const [socketInitialized, setSocketInitialized] = useState(false);
  
  const socket = useRef(null);
  const manager = useRef(null);
  
  useEffect(() => () => {
    closeConnection();
  }, []);
  
  const init = () => {
    manager.current = new Manager(process.env.REACT_APP_COLLAB_SERVICE, {
      path: process.env.REACT_APP_COLLAB_SERVICE_WS_PATH,
      host: process.env.REACT_APP_COLLAB_SERVICE_WS_HOST,
      transports: ["websocket"],
      parser: socketParser,
    });
    socket.current = manager.current.socket("/");
    setSocketInitialized(true);
  };
  
  const setupWorkspaceEvents = (on) => {
    if(!on) {
      socket.current.removeAllListeners();
      return;
    }
    socket.current.on(EditorCommands.CONNECT_ERROR, onDisconnect);
    socket.current.on(EditorCommands.DISCONNECT, onDisconnect);
    socket.current.on(EditorCommands.CONNECTION, onConnect);
    socket.current.on(EditorCommands.JOIN_WORKSPACE, onWorkspaceJoin);
    socket.current.on(EditorCommands.NOTIFICATION, onNotification);
  }
  
  // --------- connections ---------
  
  const closeConnection = () => {
    setConnectionOnline(false);
    socket.current?.close();
  };
  
  const onConnect = () => {
    socket.current.emit(EditorCommands.JOIN_WORKSPACE, {
      companyId: userStore.companyId,
      workspaceUuid: userStore.workspaceUuid,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
    });
  };
  
  const onDisconnect = (reason) => {
    console.log("DISCONNECTED", reason);
    setConnectionOnline(false);
  }
  
  const onWorkspaceJoin = (data) => {
    if(data?.joined) {
      console.log("CONNECTED");
      console.log("JOINED WORKSPACE");
      setConnectionOnline(true);
    } else {
      console.error("ERROR WHILE JOINING WORKSPACE: ", data.message)
    }
  }
  
  const onNotification = (messageData) => {
    messageStore.addMessage(messageData);
  }
  
  const sendAction = (command, payload) => {
    if (!socket.current?.connected) return;
    socket.current.emit(command, payload);
  };
  
  const requestProjectRename = (newName, projectUuid) => {
    sendAction(EditorCommands.PROJECT_RENAME, { projectUuid, newName });
  };
  
  const value = {
    socket,
    manager,
    connectionOnline,
    setConnectionOnline,
    socketInitialized,
    init,
    setupWorkspaceEvents,
    closeConnection,
    sendAction,
    
    requestProjectRename,
  };
  
  return (
    <WorkspaceSocketContext.Provider value={value}>
      {children}
    </WorkspaceSocketContext.Provider>
  );
};

WorkspaceSocketProvider.propTypes = {
  children: node.isRequired,
};
