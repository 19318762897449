import { useEffect, useState } from "react";
import {
  EditorCommentsStore,
  EditorCommentsStoreProvider, PresetStore,
  PresetStoreProvider, ProjectEditorStore,
  ProjectEditorStoreProvider,
} from "@stores";
import { fetchProjectProposalQuery, getProjectPresetQuery } from "@query";
import { useSharedProjectKey } from "@hooks";
import { Dialog, PageCircularProgress } from "@components";
import { ProposalView } from "./ProposalView";
import { useTranslation } from "react-i18next";
import { HtmlParser, PROJECT_TYPE } from "@utils";
import { useParams } from "react-router-dom";
import { db, EditorSocketProvider, WorkspaceSocketProvider } from "@client";
import { structureParser, unzipStructure } from "project-structure";

export const Proposal = () => {
  const key = useSharedProjectKey();
  const { projectHash } = useParams();
  const { t } = useTranslation();

  const [store, setStore] = useState(null);
  const [commentStore, setCommentStore] = useState(null);
  const [presetStore, setPresetStore] = useState(null);
  const [commentHash, setCommentHash] = useState(null);
  const [isRemoved, setRemoved] = useState(false);
  const [isArchived, setArchived] = useState(false);
  
  useEffect(() => {
    document.title = "Estimate";
    if(!self.location.href.includes("app.apropo")) {
      const favicon = document.getElementById("favicon");
      favicon.setAttribute(
        "href",
        self.location.href.includes("estimates.whitelabelcoders.com")
          ? "https://whitelabelcoders.com/app/themes/wlc-new-site/dist/images/favicon/favicon-32x32_f7102d9c.png"
          : "data:;base64,iVBORw0KGgo="
      );
    }
    onLoad();
  }, []);
  
  const onLoad = async () => {
    try {
      const commenter = await db.comment.where({ project: projectHash })?.toArray();
      const projectPreset = await getProjectPresetQuery(key);
      const {
        comment,
        commentUuid,
        ...projectData
      } = await fetchProjectProposalQuery(key, true);

      if (projectData.status === "archive") {
        setArchived(true);
        return;
      }
      
      for(const ver of projectData.versions) {
        ver.structure = await structureParser({
          structureData: unzipStructure(ver.structure),
          workTypes: projectData.tags
        });
      }
      setCommentHash(commentUuid);
      setCommentStore(new EditorCommentsStore({
        isProposal: true,
        commenterData: commenter?.[0],
        useComments: comment
      }))
      setPresetStore(new PresetStore(projectPreset, undefined, true))
      setStore(new ProjectEditorStore({
        projectData,
        projectType: PROJECT_TYPE.STANDARD,
        unzipped: true,
        isProposal: true,
      }));
    } catch (e) {
      if (e?.response?.data?.status === 400) setArchived(true);
      else if (
        e?.response?.data?.status === 403 ||
        e?.response?.data?.status === 404
      )
        setRemoved(true);
    }
  };

  if (isRemoved)
    return (
      <Dialog open>
        <h3>{HtmlParser(t("alerts.editor.removed_share"))}</h3>
      </Dialog>
    );

  if (isArchived)
    return (
      <Dialog open>
        <h3>{HtmlParser(t("alerts.editor.archive_share"))}</h3>
      </Dialog>
    );

  return !store ? (
    <PageCircularProgress />
  ) : (
    <WorkspaceSocketProvider>
      <ProjectEditorStoreProvider value={store}>
        <PresetStoreProvider value={presetStore}>
          <EditorCommentsStoreProvider value={commentStore}>
            <EditorSocketProvider>
              <ProposalView projectHash={commentHash} />
            </EditorSocketProvider>
          </EditorCommentsStoreProvider>
        </PresetStoreProvider>
      </ProjectEditorStoreProvider>
    </WorkspaceSocketProvider>
  );
};
