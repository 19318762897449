import { useState } from "react";
import { bool, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { MenuButton } from "@components";
import { FileAdd } from "@assets";
import { CreateTemplateDialog } from "@dialogs";

export const CreateTemplate = ({
  uuid,
  name,
  description,
  hasComments,
}) => {
  
  const { t } = useTranslation();
  
  const [templateDialog, setTemplateDialog] = useState(false);
  
  return <>
    <MenuButton
      icon={<FileAdd />}
      onClick={() => setTemplateDialog(true)}
    >
      {t("views.project.project_template.title")}
    </MenuButton>
    <CreateTemplateDialog
      projectUuid={uuid}
      open={templateDialog}
      onClose={() => setTemplateDialog(false)}
      defaultValues={{ description, name }}
      hasComments={hasComments}
    />
  </>
}

CreateTemplate.propTypes = {
  uuid: string.isRequired,
  name: string.isRequired,
  description: string,
  hasComments: bool,
}