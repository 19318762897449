import { useMemo } from "react";
import { bool, number, func, object, string, arrayOf } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { generateEntryId, hasOlderOriginBuild, RISK_BUFFER_LOCK_BUILD } from "project-structure";
import { useEditorCommentStore, useStructureStore } from "@hooks";
import { TooltipIcon, VisibilityButton } from "@components";
import { ChatThread, Overheads } from "@dialogs";
import { FileCopy } from "@material-ui/icons";
import { LibraryPusher } from "./LibraryPusher";
import { LibraryAccept } from "./LibraryAccept";
import { TaskRemove } from "./TaskRemove";
import { TaskContentTooltip } from "./TaskContentTooltip";
import { UserStoryGenerator } from "./UserStoryGenerator";
import { BreakdownRowActionMenu } from "../components/BreakdownRowActionMenu";
import { EditDocument } from "@assets";
import classnames from "classnames";

export const BreakdownRowActions = observer(({
  element,
  tableDepth,
  descriptionVisible,
  showDescriptionEditor,
  inlineDescriptionEdition,
  handleDescriptionEdition,
  showChatThread,
  allowCommenting,
  allowEdition,
  isLibrary,
  removable,
  toAccept,
  hideBadge,
  useMenu,
  descriptionClassName,
  allowDescriptionHide,
  fullIdPath,
}) => {
  const { t } = useTranslation();
  const structure = useStructureStore();
  const commentStore = useEditorCommentStore();

  const commentPath = element.treePath.join("/");
  const showOverheads = useMemo(() => Boolean(
    !hasOlderOriginBuild(RISK_BUFFER_LOCK_BUILD, structure.sOriginBuild)
  ), [structure.sOriginBuild]);

  const isCreatedElement = useMemo(
    () => !isLibrary || typeof element.id === "number",
    [element.id, isLibrary]
  );

  const lighterColor = useMemo(
    () => (tableDepth === 0 ? "color-primary-lighter" : undefined),
    [tableDepth]
  );

  const handleDuplicate = () => {
    element.duplicateSelf(
      generateEntryId(),
      element.name || t("common.unnamed")
    );
  };

  const handleDescriptionHide = (v) => {
    structure.sections.find(s => s.id === fullIdPath[0])?.setHideDescription(v);
  }

  return (
    <>
      {
        allowEdition && isCreatedElement && Boolean(element.name) && tableDepth === 0 && showOverheads &&
        <Overheads section={element} />
      }
      {!useMenu && allowEdition && isCreatedElement && Boolean(element.name) && (
        <UserStoryGenerator element={element} lighterColor={lighterColor} />
      )}
      {!useMenu && allowEdition && isCreatedElement && showDescriptionEditor && (
        <TooltipIcon
          icon={<EditDocument />}
          onClick={handleDescriptionEdition}
          className={classnames("button-description", lighterColor, "noDrag hoverIcon opaque")}
          color="primary"
        >
          {t(`views.editor.description${inlineDescriptionEdition ? "_add" : ""}`)}
        </TooltipIcon>
      )}
      {
        showChatThread && commentStore?.commentsVisible &&
        <ChatThread
          rowThread
          path={commentPath}
          className={lighterColor}
          hideBadge={hideBadge}
          allowCommenting={allowCommenting}
        />
      }
      {!useMenu && allowEdition && !isLibrary && (
        <TooltipIcon
          icon={<FileCopy />}
          color="primary"
          onClick={handleDuplicate}
          className={classnames("button-duplicate", lighterColor, "noDrag hoverIcon opaque")}
        >
          {t("views.editor.duplicate", {
            type: t("proposal_parts.module_levels.level_" + tableDepth),
          })}
        </TooltipIcon>
      )}
      {isLibrary && toAccept && (
        <LibraryAccept element={element} tableDepth={tableDepth} />
      )}
      {!useMenu && allowEdition && !isLibrary && tableDepth > 0 && (
        <LibraryPusher element={element} tableDepth={tableDepth} />
      )}
      {removable && (
        <TaskRemove
          element={element}
          tableDepth={tableDepth}
          isLibrary={isLibrary}
        />
      )}
      {
        descriptionVisible &&
        <TaskContentTooltip
          hasDescription={ showDescriptionEditor && element.hasDescription }
          description={ element.content }
          childrenWithContent={ element.childrenWithContent }
          hideBadge={ hideBadge }
          className={ classnames(lighterColor, descriptionClassName) }
          disableTooltip={ Boolean(descriptionClassName) }
        />
      }
      {
        descriptionVisible && allowDescriptionHide &&
        <VisibilityButton
          size="tiny" inRow
          hidden={Boolean(descriptionClassName)}
          onChange={handleDescriptionHide}
          className={ classnames(lighterColor, descriptionClassName) }
        />
      }
      {
        useMenu && allowEdition &&
        <BreakdownRowActionMenu
          element={element}
          className={lighterColor}
          tableDepth={tableDepth}
          isLibrary={isLibrary}
        />
      }
    </>
  );
});

BreakdownRowActions.propTypes = {
  element: object.isRequired,
  fullIdPath: arrayOf(string).isRequired,
  tableDepth: number,
  showDescriptionEditor: bool,
  inlineDescriptionEdition: bool,
  handleDescriptionEdition: func,
  showChatThread: bool,
  allowEdition: bool,
  isLibrary: bool,
  removable: bool,
  toAccept: bool,
  hideBadge: bool,
  useMenu: bool,
  descriptionVisible: bool,
  descriptionClassName: string,
  allowDescriptionHide: string,
};
