import { useEffect, useMemo } from "react";
import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import {
  useTableOverflowChecker,
  useStructureStore,
  getUserVisibleWorkTypes,
  useVisibilityModeCellClassName
} from "@hooks";
import ResizeObserver from "react-resize-observer";
import { Grid, TableContainer } from "@material-ui/core";
import { ScrollButtonContainer, SectionSummaryRow } from "@components";
import { TableHeader } from "../TableHeader/TableHeader";
import { SectionRow } from "../../../rows/SectionRow/SectionRow";
import { BreakdownRowGroup } from "../BreakdownRowGroup/BreakdownRowGroup";
import { BreakdownRowGroupList } from "../BreakdownRowGroupList";
import useStyle from "./Section.style";
import useEditorStyle from "../../../commonStyles.style";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

export const Section = observer(({
  section,
  index,
  allowEdition,
  isProposal,
  isLibrary,
  isSellerOrClient,
  useClientActions,
  noTurningOff,
  ...otherProps
}) => {
  const structure = useStructureStore();
  const classes = useStyle();
  const commonClasses = useEditorStyle();
  
  const { estimateValueLevel, settings, visibility, usesTwoValues } = structure;
  const {
    useMinMax,
    titleCellWidth,
    descriptionCellWidth,
    showPrices,
    showBreakdownColumns,
    descriptionMode,
  } = settings;
  const { apply } = visibility;
  
  const {
    taskWorkTypes,
    sectionModulesHaveChildren,
    childrenWithContent,
    showCombinedHours,
    hidePrice,
    hideDescription,
    content,
  } = section;

  const visibleWorkTypes = getUserVisibleWorkTypes(taskWorkTypes, isProposal);
  const priceClassName = useVisibilityModeCellClassName(hidePrice);
  const descriptionClassName = useVisibilityModeCellClassName(hideDescription);
  
  const inTableSection = useMemo(
    () => structure.settings.sectionFormat === 1,
    [structure.settings.sectionFormat]
  );

  const showSectionSummary = useMemo(
    () => structure.settings.sectionFormat === 2,
    [structure.settings.sectionFormat]
  );

  const tableContainerIdentifier = useMemo(() => `breakdownContainer${index}`, [index]);
  const tableIdentifier = useMemo(() => `tableHeader${index}`, [index]);

  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier);
  
  
  const pricesVisible = useMemo(
    () => showBreakdownColumns && showPrices && isSellerOrClient && (!isProposal || !apply || !hidePrice),
    [showPrices, isSellerOrClient, showBreakdownColumns, isProposal, apply, hidePrice]
  );
  
  useEffect(() => {
    onResize()
  }, [pricesVisible, useMinMax, titleCellWidth, descriptionCellWidth]);
  
  const useExpandCell = useMemo(() => (
    !isProposal || sectionModulesHaveChildren
  ), [isProposal, sectionModulesHaveChildren]);
  
  const hasAnyDescription = useMemo(() => (
    Boolean(content) || childrenWithContent > 0
  ), [content, childrenWithContent]);
  
  const descriptionVisible = useMemo(() => (
    (!isProposal || hasAnyDescription) && (!isProposal || !apply || !hideDescription)
  ), [isProposal, hasAnyDescription, apply, hideDescription]);
  
  const maxTitleCellWidth = useMemo(() => (
    descriptionMode === 3 && descriptionVisible
      ? titleCellWidth
      : undefined
  ), [descriptionMode, titleCellWidth, visibleWorkTypes, pricesVisible, useClientActions, usesTwoValues, estimateValueLevel])
  
  const childProps = {
    isSellerOrClient,
    useClientActions,
    allowEdition,
    isProposal,
    isLibrary,
    useExpandCell,
    descriptionVisible,
    maxTitleCellWidth,
    pricesVisible,
    noTurningOff,
    visibleWorkTypes,
    showCombinedHours,
    priceClassName,
    descriptionClassName,
    ...otherProps
  }
  
  return (
    <Grid item container>
      {
        isTableContentOverflowing && !isMobile &&
        <ScrollButtonContainer {...scrollButtonProps} />
      }
      { !inTableSection && (
        <SectionRow
          index={ index }
          section={ section }
          allowEdition={ allowEdition && !isLibrary }
          isLibrary={ isLibrary }
        />
      ) }
      <TableContainer
        ref={ ref }
        id={ tableContainerIdentifier }
        onScroll={ onScroll }
        className={ classnames(
          "pt-3",
          classes.root,
          isTableContentOverflowing && commonClasses.overflows
        ) }
      >
        <Grid container role="rowgroup" direction="column">
          <ResizeObserver onResize={ onResize }/>
          <TableHeader
            { ...childProps }
            section={ section }
            tableIdentifier={ tableIdentifier }
            noTurningOff={noTurningOff}
            hasAnyDescription={hasAnyDescription}
          />
          { inTableSection ? (
            <BreakdownRowGroup
              { ...childProps }
              sectionIndex={ index }
              element={ section }
              isLastGroupRow
              tableDepth={ 0 }
              isFirst={ index === 0 }
            />
          ) : (
            <BreakdownRowGroupList
              { ...childProps }
              initialDepth={ isLibrary ? section.startingLevel - 1 : 0 }
              element={ section }
              sectionIndex={ index }
              isLastGroupRow
              parentIds={ [ section.id ] }
            />
          ) }
          { !inTableSection && (showSectionSummary || allowEdition) && (
            <SectionSummaryRow
              { ...childProps }
              section={ section }
              isFirst={ index === 0 }
            />
          ) }
        </Grid>
      </TableContainer>
    </Grid>
  );
});

Section.props = {
  section: object.isRequired,
  index: number.isRequired,
  hasEditorPrivileges: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
  isSellerOrClient: bool,
  blockExpansions: bool,
  showPdfCover: bool,
  displayStatus: bool,
  allowStatusChange: bool,
  allowEdition: bool,
  showChatThread: bool,
  allowCommenting: bool,
};
