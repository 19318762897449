import { useMemo, useState } from "react";
import {
  bool,
  number,
  string,
  object,
  arrayOf,
  oneOf,
  oneOfType,
} from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore, useTableExpandHandler } from "@hooks";
import { Tables, hashCode } from "project-structure";
import { CELL_WIDTHS } from "@utils";
import { Collapse } from "@material-ui/core";
import {
  DescriptionCell,
  TitleCell,
  TaskSelect,
  LibraryTaskNameEditor,
  AddChildTask,
  DescriptionRow,
  Row,
  RowGroup,
  SummaryRow,
  WysiwygEditor,
  VisibilityButton,
} from "@components";
import { TaskWorkType } from "../TaskWorkType/TaskWorkType";
import { CombinedTaskWorkType } from "../CombinedTaskWorkType/CombinedTaskWorkType";
import { TaskPrice } from "../TaskPrice";
import { BreakdownRowGroupList } from "../BreakdownRowGroupList";
import { BreakdownRowActions } from "./components/BreakdownRowActions";
import { DescriptionVariantSelector } from "@components/ProjectEditor/DescriptionVariantSelector/DescriptionVariantSelector";
import classnames from "classnames";

export const BreakdownRowGroup = observer(({
  element,
  allowEdition,
  isSellerOrClient,
  isProposal,
  isLibrary,
  useClientActions,
  isLastGroupRow,
  blockExpansions,
  displayStatus,
  allowStatusChange,
  parentName,
  parentIds = [],
  tableDepth = 0,
  initialDepth = 0,
  isFirst,
  useExpandCell,
  descriptionVisible,
  pricesVisible,
  maxTitleCellWidth,
  visibleWorkTypes,
  showPdfCover,
  noTurningOff,
  showCombinedHours,
  priceClassName,
  descriptionClassName,
  showChatThread,
  allowCommenting,
  useVisibilityCell,
}) => {

  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { t } = useTranslation();
  
  const [descEdition, setDescEdition] = useState(undefined);

  const { estimateViewLevel, estimateValueLevel, usesAvgValue } = structure;
  const {
    showBreakdownColumns,
    descriptionMode,
    descriptionCellWidth,
    titleCellWidth,
    // showBreakdownParentValues,
  } = structure.settings;

  const {
    id: elementId,
    name,
    content,
    isTurnedOff,
    hasChildren,
    hasFixedPrice,
    hasChildrenHours,
    isOpened,
    status,
    showMoreContent,
    parentHoldsPrice,
    hasDescription,
  } = element;

  const actualDepthLevel = useMemo(
    () => tableDepth + initialDepth,
    [tableDepth, initialDepth]
  );

  const fullIdPath = useMemo(
    () => [...parentIds, elementId],
    [parentIds, elementId]
  );

  const openable = useMemo(
    () =>
      actualDepthLevel < estimateViewLevel && (allowEdition || hasChildren),
    [actualDepthLevel, estimateViewLevel, allowEdition, hasChildren]
  );

  const removable = useMemo(
    () =>
      (structure.hasMultipleSections || actualDepthLevel > 0) && allowEdition,
    [structure.hasMultipleSections, allowEdition, actualDepthLevel]
  );

  const hasEditedDescription = useMemo(
    () =>
      descriptionMode === 2 ? hasDescription || descEdition : descEdition,
    [descEdition, descriptionMode, hasDescription]
  );

  const isAcceptedComponent = useMemo(
    () => status === 1 && displayStatus,
    [status, displayStatus]
  );

  const opened = useMemo(
    () => isAcceptedComponent || (openable && (isOpened || blockExpansions)),
    [isAcceptedComponent, openable, isOpened, blockExpansions]
  );

  const isSectionRow = useMemo(
    () => actualDepthLevel === 0,
    [actualDepthLevel]
  );

  const toAccept = useMemo(
    () => displayStatus && allowStatusChange && status === 0,
    [status, displayStatus, allowStatusChange]
  );

  const showDescriptionCell = useMemo(
    () => descriptionMode === 3 && descriptionVisible,
    [descriptionMode, descriptionVisible]
  );

  const hashedPath = useMemo(
    () =>
      [Tables.BREAKDOWN, ...(parentIds || []), elementId]
        .map((id) => hashCode(id))
        .join("/"),
    [parentIds, elementId]
  );

  const handleDescriptionEdition = () => {
    setDescEdition(true);
  };

  const confirmDescriptionEdition = (desc) => {
    if (desc !== content) element.setContent(desc, false);
    setDescEdition(false);
  };

  const handleTurnOff = () => {
    element.setTurnOffState(!isTurnedOff);
  };
  
  const handleHideDescription = (v) => {
    structure.sections.find(s => s.id === fullIdPath[0])?.setHideDescription(v);
  }

  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    openable && !blockExpansions,
    isOpened,
    element.setOpenState
  );

  return (
    <RowGroup
      originTableId={Tables.BREAKDOWN}
      tableDepth={tableDepth}
      isActive={opened}
      isStatic={!allowEdition}
      elementId={elementId}
      parentIds={parentIds}
      elementType={["s", "m", "f", "t"][actualDepthLevel]}
    >
      <Row
        useExpandCell={useExpandCell}
        expandable={openable}
        expanded={opened}
        onExpand={handleOpen}
        noArrow={actualDepthLevel === 1}
        expandOnClick
        useVisibilityCell={useVisibilityCell}
        visible={!isTurnedOff}
        onVisibilityToggle={handleTurnOff}
        isFirstGroupRow={tableDepth <= 1}
        isLastGroupRow={
          isSectionRow || (isLastGroupRow && !opened && !hasEditedDescription)
        }
        highlightCellText={isAcceptedComponent}
        tableDepth={tableDepth}
        style={!tableDepth ? { marginBottom: 2 } : undefined}
      >
        <TitleCell
          allowOverflowDisplay={descriptionMode < 3}
          minWidth={showDescriptionCell ? titleCellWidth : CELL_WIDTHS.TITLE}
          maxWidth={maxTitleCellWidth}
          pinnedLeft={useExpandCell ? tableDepth || 1 : 0}
          actions={
            <BreakdownRowActions
              element={element}
              descriptionVisible={descriptionVisible}
              showDescriptionEditor={
                descriptionMode === 1 || (descriptionMode === 2 && !hasDescription)
              }
              inlineDescriptionEdition={descriptionMode === 2}
              tableDepth={tableDepth}
              handleDescriptionEdition={handleDescriptionEdition}
              showChatThread={showChatThread}
              allowCommenting={allowCommenting}
              toAccept={toAccept}
              removable={removable}
              allowEdition={allowEdition}
              isLibrary={isLibrary}
              hideBadge={opened || estimateViewLevel === actualDepthLevel}
              useMenu={showDescriptionCell}
              descriptionClassName={descriptionClassName}
              allowDescriptionHide={hasDescription && visibilityMode}
              fullIdPath={fullIdPath}
            />
          }
        >
          {isLibrary && (allowEdition || typeof elementId !== "number") ? (
            <LibraryTaskNameEditor element={element} parentIds={parentIds} />
          ) : allowEdition ? (
            <TaskSelect
              element={element}
              tableDepth={actualDepthLevel}
              fullIdPath={fullIdPath}
              parentName={parentName}
            />
          ) : (
            <span
              className={classnames(
                "name wrap-text my-3",
                !isSectionRow && "preset-titleText",
                isSectionRow && "preset-titleTextSection",
                !name?.length && "semi-transparent",
                !useExpandCell && "ml-3"
              )}
            >
              {name?.replace(/<(.|\n)*?>/g, "") || t("common.unnamed")}
            </span>
          )}
        </TitleCell>
        {showDescriptionCell && (
          <DescriptionCell
            allowOverflowDisplay
            isSectionRow={isSectionRow}
            minWidth={descriptionCellWidth}
            left={titleCellWidth}
            className={descriptionClassName}
          >
            <WysiwygEditor
              readOnly={!allowEdition}
              // blockContentButton={!useClientActions}
              blockContentButton={blockExpansions}
              noEmptyHtmlStrings
              changeOnClickAway
              placeholder={t("views.editor.desc_change")}
              onChange={confirmDescriptionEdition}
              name={`desc${hashCode(elementId)}`}
              value={content}
              toolbarVariant="popup"
              shorten
              useBorder
              showMore={showMoreContent}
              onShowMore={element.setShowMoreContent}
              moreButtonClassName={classnames(
                !isSectionRow && "preset-moreDesc",
                isSectionRow && "preset-moreDescSection color-primary-lighter"
              )}
              className={allowEdition ? "my-1-5" : "my-3 ml-0-5"}
              toolbarPlacement="top"
            >
              {!isLibrary && <DescriptionVariantSelector />}
            </WysiwygEditor>
          </DescriptionCell>
        )}
        {
          showBreakdownColumns && !showCombinedHours &&
          visibleWorkTypes?.map((workType) => (
            <TaskWorkType
              key={workType.id}
              element={element}
              workType={workType}
              editable={
                allowEdition && (!isLibrary || typeof elementId === "number")
              }
              fullIdPath={fullIdPath}
              emptyValue={
                (hasFixedPrice && !hasChildrenHours) || parentHoldsPrice
              }
              textPresetClass={isSectionRow ? "sectionTitle" : "preset-text"}
              opened={opened}
              tableDepth={tableDepth}
              actualDepthLevel={actualDepthLevel}
            />
          ))
        }
        {
          showBreakdownColumns && showCombinedHours &&
          <CombinedTaskWorkType
            element={element}
            fullIdPath={fullIdPath}
            emptyValue={
              (hasFixedPrice && !hasChildrenHours) || parentHoldsPrice
            }
            opened={opened}
            tableDepth={tableDepth}
            actualDepthLevel={actualDepthLevel}
            blockFocusAction={blockExpansions}
          />
        }
        {pricesVisible && (
          <TaskPrice
            element={element}
            editable={allowEdition}
            isLibrary={isLibrary}
            isSectionRow={isSectionRow}
            actualDepthLevel={actualDepthLevel}
            numOfVisibleWorkTypes={visibleWorkTypes.length}
            opened={opened}
            tableDepth={tableDepth}
            isLastCell={!useVisibilityCell}
            className={priceClassName}
          />
        )}
      </Row>
      {descriptionMode < 3 && descriptionVisible && (
        <DescriptionRow
          allowEdition={allowEdition}
          show={hasEditedDescription}
          value={content}
          isLastGroupRow={tableDepth <= 1 && !opened}
          onValueChange={confirmDescriptionEdition}
          tableDepth={tableDepth}
          staticDescription={descriptionMode === 2}
          isSectionRow={isSectionRow}
          isLibrary={isLibrary}
          showBorder={(hasChildren && opened) || !isLastGroupRow}
          showPdfCover={showPdfCover}
          className={descriptionClassName}
        >
          {
            visibilityMode && descriptionMode === 2 &&
            <VisibilityButton
              size="tiny"
              inRow
              hidden={Boolean(descriptionClassName)}
              onChange={handleHideDescription}
            />
          }
        </DescriptionRow>
      )}
      {openable && (
        <Collapse
          in={opened}
          className="w-full"
          mountOnEnter
          unmountOnExit
          data-id={hashCode(`${elementId}_cont`)}
          data-p={hashedPath}
          data-t={["s", "m", "f", "t"][actualDepthLevel + 1]}
          {...collapseProps}
        >
          <BreakdownRowGroupList
            element={element}
            allowEdition={allowEdition}
            tableDepth={tableDepth}
            initialDepth={initialDepth}
            isLastGroupRow={
              tableDepth === 0 || (isLastGroupRow && !allowEdition)
            }
            parentIds={fullIdPath}
            parentName={name}
            useClientActions={useClientActions}
            isLibrary={isLibrary}
            isProposal={isProposal}
            className="w-full"
            blockExpansions={blockExpansions}
            isSellerOrClient={isSellerOrClient}
            displayStatus={displayStatus}
            allowStatusChange={allowStatusChange}
            useExpandCell={useExpandCell}
            descriptionVisible={descriptionVisible}
            pricesVisible={pricesVisible}
            maxTitleCellWidth={maxTitleCellWidth}
            visibleWorkTypes={visibleWorkTypes}
            showPdfCover={showPdfCover}
            noTurningOff={noTurningOff}
            showCombinedHours={showCombinedHours}
            priceClassName={priceClassName}
            descriptionClassName={descriptionClassName}
            showChatThread={showChatThread}
            allowCommenting={allowCommenting}
            useVisibilityCell={useVisibilityCell}
          />
          {allowEdition && (
            <SummaryRow
              displayMinMax={!usesAvgValue}
              useExpandCell={!!actualDepthLevel && useExpandCell}
              useVisibilityCell={useVisibilityCell}
              emptyValueCells={
                estimateValueLevel >= 0 ? visibleWorkTypes?.length : 0
              }
              emptyPriceCell={pricesVisible}
              tableDepth={tableDepth}
              isLastGroupRow={tableDepth === 1}
              className={classnames(tableDepth === 0 && "bg-transparent")}
              priceClassName={priceClassName}
            >
              <TitleCell
                allowOverflowDisplay={descriptionMode < 3 && !tableDepth}
                minWidth={
                  showDescriptionCell ? titleCellWidth : CELL_WIDTHS.TITLE
                }
                maxWidth={maxTitleCellWidth}
                pinnedLeft={useExpandCell ? tableDepth : 0}
              >
                <AddChildTask
                  parent={element}
                  tableDepth={actualDepthLevel + 1}
                  showId={isFirst}
                />
              </TitleCell>
              {showDescriptionCell && (
                <DescriptionCell
                  allowOverflowDisplay={!!tableDepth}
                  isSectionRow={isSectionRow}
                  minWidth={descriptionCellWidth}
                  left={titleCellWidth}
                  className={descriptionClassName}
                />
              )}
            </SummaryRow>
          )}
        </Collapse>
      )}
    </RowGroup>
  );
}
);

BreakdownRowGroup.propTypes = {
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]),
  initialDepth: oneOf([0, 1, 2, 3]),

  parentIds: arrayOf(oneOfType([number, string])),
  parentName: string,

  isLastGroupRow: bool,
  displayStatus: bool,
  allowStatusChange: bool,
  isFirst: bool,

  //common
  allowEdition: bool,
  isSellerOrClient: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
  useExpandCell: bool,
  descriptionVisible: bool,
  pricesVisible: bool,
  maxTitleCellWidth: number,
  showPdfCover: bool,
  noTurningOff: bool,
  showCombinedHours: bool,
  showChatThread: bool,
  priceClassName: string,
  descriptionClassName: string,
};
